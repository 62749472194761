<template>
  <el-dialog v-el-drag-dialog :close-on-click-modal="false" :visible.sync="dialogVisible" :title="$t('common.select')"
    width="1000px" @close="$reset('form')" append-to-body top="6vh">
    <vm-table ref="vmTable" url="periodicInspectionTemplate" :filter.sync="search">
      <template slot="adSearch">
        <vm-search :label="$t('dayWeekMonthManger.filter')">
          <el-input v-model.trim="search.filter" :placeholder="$t('common.search')" clearable></el-input>
        </vm-search>
      </template>
      <el-table-column prop="templateNo" :label="$t('dayWeekMonthManger.templateNo')" width="120"
        align="center"></el-table-column>
      <el-table-column prop="templateName" :label="$t('dayWeekMonthManger.templateName')"></el-table-column>
      <el-table-column prop="isDefault" :label="$t('dayWeekMonthManger.isDefault')" width="130" align="center">
        <template slot-scope="scope">
          <el-tag type="success" v-if="!scope.row.isDefault">{{ $t('common.yes') }}</el-tag>
          <el-tag type="info" v-else>{{ $t('common.no') }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column ::label="$t('dayWeekMonthManger.operate')" width="100" align="center">
        <template slot-scope="scope">
          <el-button type="primary" @click="handleSelect(scope.row)">{{ $t("common.select") }}</el-button>
        </template>
      </el-table-column>
    </vm-table>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialogVisible: false,
      search: {
        filter: "",
      },
    };
  },

  methods: {
    open() {
      this.dialogVisible = true;
      this.$nextTick(() => {
        this.getList(1);
      });
    },

    getList(pageNum) {
      this.$refs.vmTable.getList(pageNum);
    },

    handleSelect(row) {
      this.$emit("select", row);
      this.dialogVisible = false;
    },
  },
};
</script>
