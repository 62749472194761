<template>
  <el-dialog
    class="select-dialog"
    :title="$t('common.select')+$t('elevator.elevatorParts.parts')"
    :visible.sync="dialogVisible"
    width="80%"
    top="2vh"
    append-to-body>
    <vm-table
      ref="vmTable"
      v-loading="loading"
      :filter.sync="search"
      url="parts">
      <template slot="adSearch">
        <vm-search :label="$t('maintWorkOrder.filter')">
          <el-input v-model.trim="search.filter" :placeholder="$t('common.search')" clearable></el-input>
        </vm-search>
      </template>
      <el-table-column prop="no" :label="$t('parts.no')"></el-table-column>
      <el-table-column prop="name" :label="$t('parts.name')"></el-table-column>
      <el-table-column prop="partsTypeName" :label="$t('parts.partsTypeName')"></el-table-column>
      <el-table-column prop="model" :label="$t('parts.model')"></el-table-column>
      <el-table-column :label="$t('common.operation')" width="60" align="center">
        <template slot-scope="scope">
          <el-button type="text" class="successBtn" @click="handleSelect(scope.row)">{{$t("common.select")}}</el-button>
        </template>
      </el-table-column>
    </vm-table>
  </el-dialog>
</template>

<script>

  export default {
    data() {
      return {
        dialogVisible: false,
        search: {
          filter: "",
        },
        value: "",
        loading: true,
      };
    },
    methods: {
      open(val) {
        this.dialogVisible = true;
        this.value = val;
        this.$nextTick(() => {
          this.getList(1);
        });
      },
      getList(pageIndex) {
        this.loading = false;
        this.$refs.vmTable.getList(pageIndex);
      },
      handleSelect(row) {
        this.$emit("select", row, this.value);
        this.dialogVisible = false;
      },
    },
  };
</script>

<style lang="scss" scoped>
</style>
