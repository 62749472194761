<template>
  <div>

    <div class="vm-separate">
      <el-form-item :label="$t('elevator.monitorType')" prop="monitorType">
        <el-select
          v-model="elevator.monitorType"
          :placeholder="$t('common.pleaseSelect')"
          style="width: 100%">
          <el-option :label="$l('elevator.monitorType1', '协议')" :value="1"></el-option>
          <el-option :label="$l('elevator.monitorType2', '外围传感器（布凡）')" :value="2"></el-option>
          <el-option :label="$l('elevator.monitorType3', '外围传感器（中移）')" :value="3"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item :label="$t('elevator.openStatus')">
        <el-select v-model="elevator.openStatus" :placeholder="$t('common.pleaseSelect')" style="width: 100%">
          <el-option :label="$t('elevator.open')" :value="0"></el-option>
          <el-option :label="$t('elevator.notOpen')" :value="10"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item v-show="false" :label="$t('elevator.isParallel')">
        <el-switch
          v-model="elevator.isParallel"
          :active-text="$l('elevator.yes', '是')"
          :inactive-text="$l('elevator.No', '否')">
        </el-switch>
      </el-form-item>
      <el-form-item :label="$t('deviceGateway.signalType')" prop="signalType">
        <el-input
          v-model="elevator.deviceGatewayVo.deviceGatewayStepVo.signalType"
          :placeholder="$t('common.pleaseEnter')"></el-input>
      </el-form-item>
    </div>
    <div class="vm-separate">
      <el-form-item :label="$t('elevator.dtuModel')" prop="deviceModel">
        <el-input
          v-model="elevator.deviceGatewayVo.deviceGatewayStepVo.deviceModel"
          :placeholder="$t('common.pleaseEnter')"></el-input>
      </el-form-item>
      <el-form-item :label="$t('elevator.dtuCode')" prop="dtuCode">
        <el-input
          v-model="elevator.dtuCode"
          :placeholder="$t('common.pleaseEnter')"></el-input>
      </el-form-item>
      <el-form-item :label="$t('elevator.nodeCode')" prop="nodeCode">
        <el-input v-model.trim="elevator.nodeCode" :placeholder="$t('common.pleaseEnter')" disabled></el-input>
      </el-form-item>
    </div>
    <div class="vm-separate">
      <el-form-item :label="$t('elevator.simCardNo')" prop="simCode">
        <el-input
          v-model.trim="elevator.deviceGatewayVo.deviceGatewayStepVo.simCode"
          :placeholder="$t('common.pleaseEnter')"></el-input>
      </el-form-item>
      <el-form-item :label="$t('deviceGateway.simICCID')" prop="simICCID">
        <el-input v-model.trim="elevator.deviceGatewayVo.deviceGatewayStepVo.simICCID" disabled></el-input>
      </el-form-item>
      <el-form-item :label="$l('elevator.simExpireDate', 'SIM卡到期时间')" prop="simExpireDate">
        <el-date-picker
          v-model="elevator.deviceGatewayVo.deviceGatewayStepVo.simExpireDate"
          type="date"
          :placeholder="$t('common.pleaseSelect')"
          style="width: 100%"
          value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
      </el-form-item>
    </div>
    <el-divider content-position="left">{{$l("elevator.alarmDelayTime", "报警延迟时间")}}</el-divider>
    <div class="vm-separate">
      <el-form-item :label="$t('elevator.peopleStuckAlarmDelayTime')" prop="tiringTime" label-width="240px">
        <el-input v-model="elevator.tiringTime" :placeholder="$t('common.pleaseEnter')"></el-input>
      </el-form-item>
      <div></div>
    </div>
    <div class="vm-separate">
      <el-form-item :label="$t('elevator.notAvailableAlarmDelayTime')" prop="unUseTime" label-width="240px">
        <el-input v-model="elevator.unUseTime" :placeholder="$t('common.pleaseEnter')"></el-input>
      </el-form-item>
      <div></div>
    </div>
    <div class="vm-separate">
      <el-form-item :label="$t('elevator.finishDelayTime')" prop="finishedTime" label-width="240px">
        <el-input v-model="elevator.finishedTime" :placeholder="$t('common.pleaseEnter')"></el-input>
      </el-form-item>
      <div></div>
    </div>
  </div>
</template>
<script>

  export default {
    // eslint-disable-next-line vue/require-prop-types
    props: ["elevator"],
    data() {
      return {
        videoTypeList: [
          {value: 1, label: this.$l("tpLink.shiLian", "SL-IPC-XSD001/E216")},
          {value: 2, label: this.$l("tpLink.tpLink", "TPLink")},
        ],
      };
    },
    methods: {
      getData() {
        return this.elevator;
      },
    },
  };
</script>
<style type="text/css">

</style>
