<template>
  <el-dialog
    title="选择电梯位置"
    :close-on-click-modal="false"
    :visible.sync="dialogVisible"
    append-to-body
    width="60%"
    top="2vh"
    @close="onDialogClose">
    <div class="body">
      <el-row>
        <div id="map" style="height: calc(100vh - 28vh);"></div>
        <div style="position: absolute; width: 450px; right: 10px; top: 10px;">
          <input id="searchId" type="hidden"/>
          <el-autocomplete
            v-model="searchValue"
            style="width: 430px;"
            :debounce="500"
            :fetch-suggestions="querySearch"
            :trigger-on-focus="false"
            clearable
            placeholder="查找地址"
            size="large">
            <i slot="prefix" class="el-icon-search el-input__icon"></i>
            <template slot-scope="{ item }">
              <div
                style="font-size: 11px"
                @click="handleItemClick(item.province,item.city,item.district,item.street,item.streetNumber,item.business)">
                {{item.business}}
              </div>
            </template>
            <el-button slot="append" icon="el-icon-search" @click="handleBtnClick()"></el-button>
          </el-autocomplete>
        </div>
      </el-row>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="handleClear">{{$t("common.clear")}}</el-button>
      <el-button @click="dialogVisible = false">{{$t("common.cancel")}}</el-button>
      <el-button type="primary" @click="handleSubmit">{{$t("common.confirm")}}</el-button>
    </span>
  </el-dialog>
</template>
<script>
  const BMap = window.BMap;

  export default {
    data() {
      return {
        dialogVisible: false,
        map: null,
        searchValue: "",
        longitude: "",
        latitude: "",
      };
    },
    methods: {
      open(longitude, latitude) {
        this.dialogVisible = true;
        this.longitude = longitude;
        this.latitude = latitude;
        this.$nextTick(() => {
          this.initBaiduMap();
        });
      },
      // 百度地图
      initBaiduMap() {
        setTimeout(() => {
          this.map = new BMap.Map("map");
          this.map.enableScrollWheelZoom();
          if (this.longitude) {
            let point = new BMap.Point(this.longitude, this.latitude);
            let marker = new BMap.Marker(point);
            this.map.centerAndZoom(point, 16);
            this.map.addOverlay(marker);
          } else {
            this.map.centerAndZoom("上海市", 11);
          }
          this.map.addEventListener("click", this.showInfo);
        }, 300);
      },
      showInfo(e) {
        this.map.clearOverlays();
        this.longitude = e.point.lng;
        this.latitude = e.point.lat;
        let marker = new BMap.Marker(e.point);
        this.map.addOverlay(marker);
      },
      querySearch(queryString, cb) {
        let ac = new BMap.Autocomplete({
          input: "searchId",
          location: this.map,
          onSearchComplete: function(result) {
            let searchValueList = [];
            for (let i = 0; i < result.getNumPois(); i++) {
              let itemObj = {};
              if (result.getPoi(i)) {
                itemObj.province = result.getPoi(i).province;
                itemObj.city = result.getPoi(i).city;
                itemObj.district = result.getPoi(i).district;
                itemObj.street = result.getPoi(i).street;
                itemObj.streetNumber = result.getPoi(i).streetNumber;
                itemObj.business = result.getPoi(i).business;
                searchValueList.push(itemObj);
              }
            }
            cb(searchValueList);
          },
        });
        ac.search(queryString);
      },
      handleItemClick(province, city, district, street, streetNumber, business) {
        this.searchValue = business;
        this.setPlace(province + city + district + street + streetNumber + business);
      },
      setPlace(searchAddress) {
        this.map.clearOverlays();
        this.local = new BMap.LocalSearch(this.map, {
          onSearchComplete: () => {
            let pp = this.local.getResults().getPoi(0).point;
            this.longitude = pp.lng;
            this.latitude = pp.lat;
            this.map.centerAndZoom(pp, 18);
            this.marker = new BMap.Marker(pp);
            this.map.addOverlay(this.marker);
          },
        });
        this.local.search(searchAddress);
      },
      handleBtnClick() {
        let currentCityName;
        let currentCity = new BMap.LocalCity();
        currentCity.get(result => {
          currentCityName = result.name;

          let myGeo = new BMap.Geocoder();
          myGeo.getPoint(
            this.searchValue,
            point => {
              if (point) {
                this.longitude = point.lng;
                this.latitude = point.lat;
                this.map.centerAndZoom(point, 18);
                this.marker = new BMap.Marker(point);
                this.map.addOverlay(this.marker);
              } else {
                this.$message.error("您输入的地址没有解析到结果!");
              }
            },
            currentCityName,
          );
        });
      },
      handleSubmit() {
        this.$emit("select", {"longitude": this.longitude, "latitude": this.latitude});
        this.dialogVisible = false;
      },
      handleClear() {
        this.longitude = "";
        this.latitude = "";
        this.$nextTick(() => {
          this.initBaiduMap();
        });
      },
      onDialogClose() {
        this.longitude = "";
        this.latitude = "";
      },
    },
  };
</script>

<style lang="scss" scoped>
</style>
