<template>
  <el-dialog class="select-dialog" :title="$t('common.select') + $t('elevatorLcd.elevatorLcd')"
    :visible.sync="dialogVisible" width="80%" top="5vh" append-to-body>
    <vm-table ref="vmTable" v-loading="loading" :filter.sync="search" url="elevatorLcds">
      <template slot="adSearch">
        <vm-search :label="$t('maintWorkOrder.filter')">
          <el-input v-model.trim="search.filter" :placeholder="$t('common.pleaseEnter')" clearable></el-input>
        </vm-search>
      </template>
      <el-table-column prop="elevatorNo" :label="$t('elevatorLcd.elevatorNo')"></el-table-column>
      <el-table-column prop="elevatorName" :label="$t('elevatorLcd.elevatorName')"></el-table-column>
      <el-table-column prop="elevatorRegisterNo" :label="$t('elevatorLcd.elevatorRegisterNo')"></el-table-column>
      <el-table-column prop="elevatorDtuCode" :label="$t('elevatorLcd.elevatorDtuCode')"></el-table-column>
      <el-table-column prop="useUnitName" :label="$t('elevatorLcd.useUnitName')"></el-table-column>
      <el-table-column prop="terminalID" :label="$t('elevatorLcd.terminalID')"></el-table-column>
      <el-table-column :label="$t('common.operation')" width="100" align="center">
        <template slot-scope="scope">
          <el-button type="text" style="color: #00C291;" @click="handleSelect(scope.row)">{{ $t("common.select")
            }}</el-button>
        </template>
      </el-table-column>
    </vm-table>
  </el-dialog>
</template>

<script>

const moduleName = "elevatorLcds";

export default {
  data() {
    return {
      dialogVisible: false,
      loading: true,
      search: {
        isBind: 0,//1.查看所有终端 2.查看已绑定电梯的终端 3.查看未绑定电梯的终端
        filter: "",
      },
    };
  },
  methods: {
    open(isBind) {
      this.search.isBind = isBind;
      this.dialogVisible = true;
      this.$nextTick(() => {
        this.getList(1);
      });
      this.loading = false;
    },
    getList(pageNum) {
      this.$refs.vmTable.getList(pageNum);
    },
    handleSelect(row) {
      this.$emit("select", row);
      this.dialogVisible = false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
