<template>
  <div v-loading="connectLoading">
    <el-tag style="width: 100%;text-align: center" :type="dtuState" size="medium" effect="dark">
      {{elevator.dtuCode}}:{{elevator.nodeCode}}
    </el-tag>
    <div v-if="connectState === '连接断开'" class="smallStatusButton">
      <el-button type="primary" style="width: 100%" @click="reConnect()">
        重连
      </el-button>
    </div>
    <table>
      <tr>
        <td style="font-weight: bold">状态:{{FA.currentState}}</td>
      </tr>
      <tr>
        <td>采集状态:{{FA.currentCollectState}}</td>
      </tr>
      <tr>
        <td>故障代码:{{FA.faultCode}}</td>
      </tr>
      <tr>
        <td>是否校准:{{FA.calibration}}</td>
      </tr>
    </table>
    <div v-if="FA.toTerminal==='是'" style="padding-top: 10px;padding-bottom: 5px;font-weight: bold">
      上载的文件信息
    </div>
    <table v-if="FA.toTerminal==='是'">
      <tr>
        <td>采样时间:</td>
      </tr>
      <tr>
        <td>{{FA.collectTime}}</td>
      </tr>
      <tr>
        <td>采样频率: {{frequencys[FA.collectFrequency]}}</td>
      </tr>
      <tr>
        <td>采样类型:{{FA.collectTypeName}}</td>
      </tr>
      <tr>
        <td>文件大小:{{FA.fileSize}}</td>
      </tr>
      <tr>
        <td>已经上载大小:{{FA.uploadSize}}</td>
      </tr>
    </table>
    <div style="padding-top: 10px;padding-bottom: 5px;font-weight: bold">本周期内已采集信息</div>
    <table>
      <tr>
        <td>上行楼层跨度:{{FA.upFloor}}</td>
      </tr>
      <tr>
        <td>下行楼层跨度:{{FA.downFloor}}</td>
      </tr>
      <tr>
        <td>开关门楼层</td>
      </tr>
      <tr>
        <td>
          <div style="float: left">
            <div
              style="width: 20px;height: 16px;float:left;margin-left:2px;margin-top: 2px;background-color: #67c23a;color:white;text-align: center"
              v-for="item in FA.trueFloors" :key="item">
              {{item}}
            </div>
          </div>
        </td>
      </tr>
    </table>
  </div>
</template>
<script>
  import {collectFrequency, typeToArray} from "@/util/constant";

  const wsUrl = window.config.wsUrl;
  //  test
  // const wsUrl = "wss://ws.elevatorstar.com:9091/websocket";
  const reconnectInterval = 5000; // 重试间隔时间,5秒
  const heartbeatTime = 9000; // 心跳间隔时间,90秒
  const pingProto = JSON.stringify({type: "101"}); // ping消息
  export default {
    components: {},
    /* eslint-disable */
    props: [],
    data() {
      return {
        dtuState: "info",
        elevator: {
          id: 0,
          dtuCode: this.dtuCode,
          nodeCode: this.nodeCode,
          registerNo: "",
          floor: [],
        },
        FA: {
          currentState: "",
          currentCollectState: "",
          toTerminal: "",
          calibration: "",
          faultCode: "",
          collectTime: "",
          collectFrequency: "",
          collectTypeName: "",
          fileSize: "",
          uploadSize: "",
          upFloor: "",
          downFloor: "",
          trueFloors: [],
        },
        loading: false,
        createTime: "",
        connectState: "",
        ws: null,
        lockReconnect: false, // 避免重复连接
        reconnectNum: 3000, // 重试次数
        connectLoading: true,
        heartTimer: null,
        faTimer: null,
        dataTimer: null,
        selectFrequencyValue: "",
        setCycleValue: "",
        setDoorCycleValue: "",
        setFrequencyValue: "",
        setTotalSwitch: false,
        setCollectSwitch: false,
        setAutoUploadSwitch: false,
        setDoorCollectSwitch: false,
        frequencys: [],
        frequencyList: typeToArray(collectFrequency),
      };
    },
    watch: {},
    methods: {
      getElevator() {
        this.contentLoading = true;
        this.saveDisabled = true;
        this.$api.getData("elevators/" + this.elevator.dtuCode + "/" + this.elevator.nodeCode).then(res => {
          this.elevator.registerNo = res.data.regNo;
          console.log(this.elevator);
          this.createWs();
        }).catch((error) => {
        });
      },
      load(dtuCode, nodeCode) {
        this.elevator.dtuCode = dtuCode;
        this.elevator.nodeCode = nodeCode;

        this.frequencys = collectFrequency;
        this.getElevator();
      },
      close() {
        this.closeWs();
      },
      createWs() {
        if (this.ws != null) {
          this.ws.close();
        }
        this.connectState = "开始连接";
        this.connectLoading = true;
        try {
          this.ws = new WebSocket(wsUrl);
          this.initEventHandle();
        } catch (e) {
          this.reconnectWs();
        }
      },
      closeWs() {
        console.log("ws close");
        try {
          if (this.ws != null) {
            this.ws.close();
            this.ws = null;
          }
          this.connectState = "";
          this.lockReconnect = true;
          this.reconnectNum = 3000;
          this.connectLoading = true;
        } catch (e) {
          console.log(e);
        }
      },
      reconnectWs() {
        if (!this.lockReconnect) {
          this.lockReconnect = true;
          // 没连接上会一直重连，设置延迟避免请求过多
          setTimeout(() => {
            if (this.reconnectNum > 0) {
              this.reconnectNum--;
              this.createWs();
              this.lockReconnect = false;
            }
          }, reconnectInterval);
        }
      },
      initEventHandle() {
        console.log("world:" + this.elevator.registerNo);
        this.ws.onclose = () => {
          this.connectState = "连接断开";
          this.dtuState = "info";
          this.connectLoading = false;
        };
        this.ws.onerror = () => {
          this.connectState = "连接异常";
          this.dtuState = "info";
          this.connectLoading = false;
          this.reconnectWs();
        };
        this.ws.onopen = () => {
          this.connectState = "开始认证";
          this.connectLoading = true;
          let authProto = {
            t: "B04",
            d: {
              regCode: this.elevator.registerNo,
            },
          };
          this.ws.send(JSON.stringify(authProto)); // 发送认证信息
        };
        this.ws.onmessage = (event) => {
          this.connectState = "连接正常";
          try {
            console.log("proto", this.elevator.dtuCode);
            let proto = JSON.parse(event.data);
            console.log("proto", proto);
            this.tValue = proto.t;
            if (this.tValue === "A05" || this.tValue === "A08" || this.tValue === "A06" || this.tValue === "A10" || this.tValue === "A11") {
              this.dtuState = "info";
            }
            if (this.tValue === "A09") {
              this.dtuState = "success";
              this.connectState = "连接正常";
              this.connectLoading = false;
              this.FA = proto.d;
            } else if (this.tValue === "A05") {
            }
          } catch (e) {
            console.log(e);
          }
        };
      },
      heartbeatStart() {
        this.timeoutObj = setTimeout(() => {
          // 这里发送一个心跳,后端收到后,返回一个心跳消息,onmessage拿到返回的心跳就说明连接正常
          this.ws.send(pingProto);
        }, heartbeatTime);
      },
      heartbeatReset() {
        console.log("reset");
        clearTimeout(this.timeoutObj);
        this.heartbeatStart();
      },
      requestData(type) {
        console.log("请求数据：" + type);
        let requestProto = {
          type: type,
        };
        this.ws.send(JSON.stringify(requestProto));
      },
      reConnect() {
        this.createWs();
      },
    },
  };
</script>
<style type="text/css">

</style>
