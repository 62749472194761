<template>
  <el-dialog
    title="EOCD"
    :close-on-click-modal="false"
    :visible.sync="dialogVisible"
    :before-close="handleDialogClose"
    width="1200px"
    top="2vh"
    class="small-padding">
    <div>
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane :label="$t('vibrationData.tab1')" name="first">
          <vm-table
            ref="vmTable"
            v-loading="loading"
            :filter.sync="search"
            :url="url"
            @clear="clearDateRange()">
            <template slot="adSearch">
              <div class="vm-separate">
                <vm-search :label="$l('maintWorkOrder.filter','关键字检索')">
                  <el-input v-model.trim="search.filter" :placeholder="$t('common.search')" clearable></el-input>
                </vm-search>
                <vm-search :label="$l('vibrationData.collectType','采集类型')">
                  <el-select
                    v-model="search.collectType"
                    :placeholder="$t('common.pleaseSelect')"
                    style="width: 100%"
                    clearable>
                    <el-option label="便携式手动采集" :value="0"></el-option>
                    <el-option label="自动采集上行" :value="1"></el-option>
                    <el-option label="自动采集下行" :value="2"></el-option>
                    <el-option label="手动采集" :value="3"></el-option>
                    <el-option label="开关门采集" :value="4"></el-option>
                    <el-option label="报警数据" :value="5"></el-option>
                  </el-select>
                </vm-search>
                <vm-search :label="$t('vibrationData.createTime')">
                  <el-date-picker
                    v-model="dateRange"
                    value-format="yyyy-MM-dd"
                    type="daterange"
                    :range-separator="$t('common.to')"
                    :start-placeholder="$t('common.startDate')"
                    :end-placeholder="$t('common.endDate')"
                    @change="handleDateChange">
                  </el-date-picker>
                </vm-search>
              </div>
            </template>
            <el-table-column prop="pkid" :label="$t('vibrationData.idNo')" width="100px"></el-table-column>
            <el-table-column
              prop="elevatorName"
              :label="$t('vibrationData.elevatorName')"
              width="280px"></el-table-column>
            <el-table-column prop="createdTime" :label="$t('vibrationData.createTime')" width="200px"></el-table-column>
            <el-table-column prop="isUpDown" :label="$t('vibrationData.collectType')" width="120px">
              <template slot-scope="scope">
                <span v-if="scope.row.collectType === 0">
                  便携式手动采集
                </span>
                <span v-else-if="scope.row.collectType === 1">
                  自动采集上行
                </span>
                <span v-else-if="scope.row.collectType === 2">
                  自动采集下行
                </span>
                <span v-else-if="scope.row.collectType === 3">
                  手动采集
                </span>
                <span v-else-if="scope.row.collectType === 4">
                  开关门采集
                </span>
                <span v-else-if="scope.row.collectType === 5">
                  报警数据
                </span>
              </template>
            </el-table-column>
            <el-table-column prop="dataDesc" :label="$t('vibrationData.dataDesc')"></el-table-column>
            <el-table-column :label="$t('common.operation')" width="140px" align="center">
              <template slot-scope="scope">
                <el-button type="primary" @click="handleCurve(scope.row)">
                  {{$t("vibrationData.curve")}}
                </el-button>
                <el-button type="primary" @click="handleDown(scope.row)">
                  {{$t("vibrationData.downLoad")}}
                </el-button>
              </template>
            </el-table-column>
          </vm-table>
        </el-tab-pane>
        <el-tab-pane :label="$t('vibrationData.tab2')" name="second">
          <div
            style="padding-bottom: 15px;">
            <el-row>
              <el-col :span="4">
                <eocd-monitor style="padding-top: 14px" ref="eocdMonitor"></eocd-monitor>
              </el-col>
              <el-col :span="20" style="height: 550px">
                <el-tabs v-model="activeName2" style="padding-left: 20px">
                  <el-tab-pane label="查询文件" name="first">
                    <div style="height: 35px;">
                      <div style="width: 14%;float: left">
                        <el-button type="primary" style="width: 90%" @click="handleCalibration()">
                          {{$t("vibrationData.calibration")}}
                        </el-button>
                      </div>
                      <div style="width: 14%;float: left;">
                        <el-button type="primary" style="width: 90%" @click="handleSearchEOCDData()">
                          {{$t("vibrationData.searchEOCDData")}}
                        </el-button>
                      </div>
                      <div style="width: 14%;float: left">
                        <el-button type="primary" style="width: 90%" @click="handleStartCollect()">
                          {{$t("vibrationData.startCollect")}}
                        </el-button>
                      </div>
                      <div style="width: 14%;float: left">
                        <el-select v-model="selectFrequencyValue" style="width: 100px;">
                          <el-option
                            v-for="item in frequencyList"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                          </el-option>
                        </el-select>
                      </div>
                      <div style="width: 14%;float: left">
                        <el-button type="primary" style="width: 90%" @click="handleEndCollect()">
                          {{$t("vibrationData.endCollect")}}
                        </el-button>
                      </div>
                      <div style="width: 14%;float: left">
                        <el-button type="primary" style="width: 90%" @click="handleClearCollect()">
                          {{$t("vibrationData.clearCollect")}}
                        </el-button>
                      </div>
                      <div style="width: 14%;float: left">
                        <el-button type="primary" style="width: 90%" @click="handleCancelCollect()">
                          {{$t("vibrationData.cancelCollect")}}
                        </el-button>
                      </div>
                    </div>
                    <el-table
                      ref="vmTable2"
                      v-loading="loading2"
                      height="450px"
                      border
                      :default-sort="{prop: 'collectTime', order: 'descending'}"
                      :data="tableData2">
                      <el-table-column
                        prop="collectTime"
                        sortable
                        :label="$t('vibrationData.collectTime')"></el-table-column>
                      <el-table-column :label="$t('vibrationData.collectFrequency')">
                        <template slot-scope="scope">
                          {{frequencys[scope.row.collectFrequency]}}
                        </template>
                      </el-table-column>
                      <el-table-column
                        prop="collectTypeName"
                        sortable
                        :label="$t('vibrationData.collectType')"></el-table-column>
                      <el-table-column :label="$t('common.operation')" width="120px" align="center">
                        <template slot-scope="scope">
                          <el-button type="primary" @click="handleCollectDown(scope.row)">
                            {{$t("vibrationData.downLoad")}}
                          </el-button>
                          <el-button @click="handleCollectDelete(scope.row)">{{$t("common.delete")}}</el-button>
                        </template>
                      </el-table-column>
                    </el-table>
                  </el-tab-pane>
                  <el-tab-pane v-if="eocdParamAuth" label="采集参数" name="second">
                    <div style="width: 84%;padding-left: 8%">
                      <div style="padding-top: 30px;padding-left: 20px;height: 30px;">
                        <div style="width: 25%;float: left;text-align:right;line-height: 28px">
                          {{$t("vibrationData.parTotalSwitch")}}
                        </div>
                        <div style="width: 30%;float: left;padding-left:15%;line-height: 28px">
                          <el-switch
                            v-model="setTotalSwitch"
                            active-text="开"
                            inactive-text="关">
                          </el-switch>
                        </div>
                        <div style="width: 30%;float: left">
                          <div style="width: 30%;float: left;margin-left: 15%">
                            <el-button type="primary" style="width: 100%" @click="handleReadParam(0)">
                              {{$t("vibrationData.read")}}
                            </el-button>
                          </div>
                          <div style="width: 30%;float: left;margin-left: 10%">
                            <el-button type="primary" style="width: 100%" @click="handleSetParam(0)">
                              {{$t("vibrationData.set")}}
                            </el-button>
                          </div>
                        </div>
                      </div>
                      <div style="padding-top: 10px;padding-left: 20px;height: 30px;">
                        <div style="width: 25%;float: left;text-align:right;line-height: 28px">
                          {{$t("vibrationData.parCollectSwitch")}}
                        </div>
                        <div style="width: 30%;float: left;padding-left:15%;line-height: 28px">
                          <el-switch
                            v-model="setCollectSwitch"
                            active-text="开"
                            inactive-text="关">
                          </el-switch>
                        </div>
                        <div style="width: 30%;float: left">
                          <div style="width: 30%;float: left;margin-left: 15%">
                            <el-button type="primary" style="width: 100%" @click="handleReadParam(1)">
                              {{$t("vibrationData.read")}}
                            </el-button>
                          </div>
                          <div style="width: 30%;float: left;margin-left: 10%">
                            <el-button type="primary" style="width: 100%" @click="handleSetParam(1)">
                              {{$t("vibrationData.set")}}
                            </el-button>
                          </div>
                        </div>
                      </div>
                      <div style="padding-top: 10px;padding-left: 20px;height: 30px;">
                        <div style="width: 25%;float: left;text-align:right;line-height: 28px">
                          {{$t("vibrationData.parCollectCycle")}}
                        </div>
                        <div style="width: 30%;float: left;padding-left:15%;line-height: 28px">
                          <el-input-number
                            v-model="setCycleValue"
                            style="width: 100px;"
                            controls-position="right"
                            :min="0"
                            :max="255"></el-input-number>
                          hour
                        </div>
                        <div style="width: 30%;float: left">
                          <div style="width: 30%;float: left;margin-left: 15%">
                            <el-button type="primary" style="width: 100%" @click="handleReadParam(2)">
                              {{$t("vibrationData.read")}}
                            </el-button>
                          </div>
                          <div style="width: 30%;float: left;margin-left: 10%">
                            <el-button type="primary" style="width: 100%" @click="handleSetParam(2)">
                              {{$t("vibrationData.set")}}
                            </el-button>
                          </div>
                        </div>
                      </div>
                      <div style="padding-top: 10px;padding-left: 20px;height: 30px;">
                        <div style="width: 25%;float: left;text-align:right;line-height: 28px">
                          {{$t("vibrationData.parCollectFrequency")}}
                        </div>
                        <div style="width: 30%;float: left;padding-left:15%;line-height: 28px">
                          <el-select v-model="setFrequencyValue" style="width: 100px;">
                            <el-option
                              v-for="item in frequencyList"
                              :key="item.value"
                              :label="item.label"
                              :value="item.value">
                            </el-option>
                          </el-select>
                        </div>
                        <div style="width: 30%;float: left">
                          <div style="width: 30%;float: left;margin-left: 15%">
                            <el-button type="primary" style="width: 100%" @click="handleReadParam(3)">
                              {{$t("vibrationData.read")}}
                            </el-button>
                          </div>
                          <div style="width: 30%;float: left;margin-left: 10%">
                            <el-button type="primary" style="width: 100%" @click="handleSetParam(3)">
                              {{$t("vibrationData.set")}}
                            </el-button>
                          </div>
                        </div>
                      </div>
                      <div style="padding-top: 10px;padding-left: 20px;height: 30px;">
                        <div style="width: 25%;float: left;text-align:right;line-height: 28px">
                          {{$t("vibrationData.autoUpload")}}
                        </div>
                        <div style="width: 30%;float: left;padding-left:15%;line-height: 28px">
                          <el-switch
                            v-model="setAutoUploadSwitch"
                            active-text="开"
                            inactive-text="关">
                          </el-switch>
                        </div>
                        <div style="width: 30%;float: left">
                          <div style="width: 30%;float: left;margin-left: 15%">
                            <el-button type="primary" style="width: 100%" @click="handleReadParam(4)">
                              {{$t("vibrationData.read")}}
                            </el-button>
                          </div>
                          <div style="width: 30%;float: left;margin-left: 10%">
                            <el-button type="primary" style="width: 100%" @click="handleSetParam(4)">
                              {{$t("vibrationData.set")}}
                            </el-button>
                          </div>
                        </div>
                      </div>
                      <div v-if="false" style="padding-top: 10px;padding-left: 20px;height: 30px;">
                        <div style="width: 25%;float: left;text-align:right;line-height: 28px">
                          {{$t("vibrationData.doorCollectSwitch")}}
                        </div>
                        <div style="width: 30%;float: left;padding-left:15%;line-height: 28px">
                          <el-switch
                            v-model="setDoorCollectSwitch"
                            active-text="开"
                            inactive-text="关">
                          </el-switch>
                        </div>
                        <div style="width: 30%;float: left">
                          <div style="width: 30%;float: left;margin-left: 15%">
                            <el-button type="primary" style="width: 100%" @click="handleReadParam(5)">
                              {{$t("vibrationData.read")}}
                            </el-button>
                          </div>
                          <div style="width: 30%;float: left;margin-left: 10%">
                            <el-button type="primary" style="width: 100%" @click="handleSetParam(5)">
                              {{$t("vibrationData.set")}}
                            </el-button>
                          </div>
                        </div>
                      </div>
                      <div v-if="false" style="padding-top: 10px;padding-left: 20px;height: 30px;">
                        <div style="width: 25%;float: left;text-align:right;line-height: 28px">
                          {{$t("vibrationData.doorCollectCycle")}}
                        </div>
                        <div style="width: 30%;float: left;padding-left:15%;line-height: 28px">
                          <el-input-number
                            v-model="setDoorCycleValue"
                            style="width: 100px;"
                            controls-position="right"
                            :min="0"
                            :max="255"></el-input-number>
                          day
                        </div>
                        <div style="width: 30%;float: left">
                          <div style="width: 30%;float: left;margin-left: 15%">
                            <el-button type="primary" style="width: 100%" @click="handleReadParam(6)">
                              {{$t("vibrationData.read")}}
                            </el-button>
                          </div>
                          <div style="width: 30%;float: left;margin-left: 10%">
                            <el-button type="primary" style="width: 100%" @click="handleSetParam(6)">
                              {{$t("vibrationData.set")}}
                            </el-button>
                          </div>
                        </div>
                      </div>
                      <div style="padding-top: 10px;padding-left: 20px;height: 30px;">
                        <div style="width: 25%;float: left;text-align:right;line-height: 28px">
                          {{$t("vibrationData.alarmFileSwitch")}}
                        </div>
                        <div style="width: 30%;float: left;padding-left:15%;line-height: 28px">
                          <el-switch
                            v-model="setAlarmFileSwitch"
                            active-text="开"
                            inactive-text="关">
                          </el-switch>
                        </div>
                        <div style="width: 30%;float: left">
                          <div style="width: 30%;float: left;margin-left: 15%">
                            <el-button type="primary" style="width: 100%" @click="handleReadParam(7)">
                              {{$t("vibrationData.read")}}
                            </el-button>
                          </div>
                          <div style="width: 30%;float: left;margin-left: 10%">
                            <el-button type="primary" style="width: 100%" @click="handleSetParam(7)">
                              {{$t("vibrationData.set")}}
                            </el-button>
                          </div>
                        </div>
                      </div>
                      <div style="padding-top: 10px;padding-left: 20px;height: 30px;">
                        <div style="width: 25%;float: left;text-align:right;line-height: 28px">
                          {{$t("vibrationData.safetyHazardSwitch")}}
                        </div>
                        <div style="width: 30%;float: left;padding-left:15%;line-height: 28px">
                          <el-switch
                            v-model="safetyHazardSwitch"
                            active-text="开"
                            inactive-text="关">
                          </el-switch>
                        </div>
                        <div style="width: 30%;float: left">
                          <div style="width: 30%;float: left;margin-left: 15%">
                            <el-button type="primary" style="width: 100%" @click="handleReadParam(50)">
                              {{$t("vibrationData.read")}}
                            </el-button>
                          </div>
                          <div style="width: 30%;float: left;margin-left: 10%">
                            <el-button type="primary" style="width: 100%" @click="handleSetParam(50)">
                              {{$t("vibrationData.set")}}
                            </el-button>
                          </div>
                        </div>
                      </div>
                      <div style="padding-top: 10px;padding-left: 20px;height: 30px;">
                        <div style="width: 25%;float: left;text-align:right;line-height: 28px">
                          {{$t("vibrationData.safetyHazardRecord")}}
                        </div>
                        <div style="width: 30%;float: left;padding-left:15%;line-height: 28px">
                          <el-switch
                            v-model="safetyHazardRecord"
                            active-text="开"
                            inactive-text="关">
                          </el-switch>
                        </div>
                        <div style="width: 30%;float: left">
                          <div style="width: 30%;float: left;margin-left: 15%">
                            <el-button type="primary" style="width: 100%" @click="handleReadParam(51)">
                              {{$t("vibrationData.read")}}
                            </el-button>
                          </div>
                          <div style="width: 30%;float: left;margin-left: 10%">
                            <el-button type="primary" style="width: 100%" @click="handleSetParam(51)">
                              {{$t("vibrationData.set")}}
                            </el-button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </el-tab-pane>
                  <el-tab-pane label="报警参数" name="third">

                    <div style="width: 100%;padding-bottom: 20px;">
                      <div style="width: 100px;float: right;margin-right: 100px;margin-left: 20px">
                        <el-button type="primary" style="width: 100%" @click="handleSetParam(999)">
                          {{$t("vibrationData.set")}}
                        </el-button>
                      </div>
                      <div style="width: 100px;float: right;">
                        <el-button type="primary" style="width: 100%" @click="handleReadParam(999)">
                          {{$t("vibrationData.read")}}
                        </el-button>
                      </div>
                    </div>
                    <div style="width: 90%;padding-left: 8%">
                      <div style="padding-top: 30px;padding-left: 20px;height: 30px;">
                        <div style="width: 100px;float: left;text-align:right;line-height: 28px;">
                          紧急停靠
                        </div>
                        <div style="width: 83%;float: left;padding-left:3%;line-height: 28px;">
                          <div style="width: 95px;float: left;text-align: right;padding-right: 10px">vend:</div>
                          <el-input-number
                            v-model="alarmParam.vend1"
                            style="width: 100px;float: left;"
                            controls-position="right"
                            :min="0"
                            :max="255"></el-input-number>
                        </div>
                      </div>
                      <div style="padding-top: 10px;padding-left: 20px;height: 30px;">
                        <div style="width: 100px;float: left;text-align:right;line-height: 28px">
                          急停
                        </div>
                        <div style="width: 83%;float: left;padding-left:3%;line-height: 28px;">
                          <div style="width: 95px;float: left;text-align: right;padding-right: 10px">vend:</div>
                          <el-input-number
                            v-model="alarmParam.vend2"
                            style="width: 100px;float: left;"
                            controls-position="right"
                            :min="0"
                            :max="255"></el-input-number>
                        </div>
                      </div>
                      <div style="padding-top: 10px;padding-left: 20px;height: 30px;">
                        <div style="width: 100px;float: left;text-align:right;line-height: 28px">
                          溜车判断
                        </div>
                        <div style="width: 83%;float: left;padding-left:3%;line-height: 28px;">
                          <div style="width: 95px;float: left;text-align: right;padding-right: 10px">maxZA:</div>
                          <el-input-number
                            v-model="alarmParam.rollMaxZa"
                            style="width: 100px;float: left;"
                            controls-position="right"
                            :min="0"
                            :max="255"></el-input-number>
                          <div style="width: 95px;float: left;text-align: right;padding-right: 10px">dz95:</div>
                          <el-input-number
                            v-model="alarmParam.rollDz95"
                            style="width: 100px;float: left;"
                            controls-position="right"
                            :min="0"
                            :max="255"></el-input-number>
                          <div style="width: 95px;float: left;text-align: right;padding-right: 10px">v:</div>
                          <el-input-number
                            v-model="alarmParam.rollV"
                            style="width: 100px;float: left;"
                            controls-position="right"
                            :min="0"
                            :max="255"></el-input-number>
                        </div>
                      </div>
                      <div style="padding-top: 10px;padding-left: 20px;height: 30px;">
                        <div style="width: 100px;float: left;text-align:right;line-height: 28px">
                          较长时间振动
                        </div>
                        <div style="width: 83%;float: left;padding-left:3%;line-height: 28px;">
                          <div style="width: 95px;float: left;text-align: right;padding-right: 10px">A1_LP_max:</div>
                          <el-input-number
                            v-model="alarmParam.longA1Max"
                            style="width: 100px;float: left;"
                            controls-position="right"
                            :min="0"
                            :max="255">
                          </el-input-number>
                          <div style="width: 95px;float: left;text-align: right;padding-right: 10px">dz95_LP_max:</div>
                          <el-input-number
                            v-model="alarmParam.longDz95Max"
                            style="width: 100px;float: left;"
                            controls-position="right"
                            :min="0"
                            :max="255"></el-input-number>
                          <div style="width: 95px;float: left;text-align: right;padding-right: 10px">dz95:</div>
                          <el-input-number
                            v-model="alarmParam.longDz95"
                            style="width: 100px;float: left;"
                            controls-position="right"
                            :min="0"
                            :max="255"></el-input-number>
                        </div>
                      </div>
                      <div style="padding-top: 10px;padding-left: 20px;height: 30px;">
                        <div style="width: 100px;float: left;text-align:right;line-height: 28px">
                          局部振动异常
                        </div>
                        <div style="width: 83%;float: left;padding-left:3%;line-height: 28px;">
                          <div style="width: 95px;float: left;text-align: right;padding-right: 10px">maxZA:</div>
                          <el-input-number
                            v-model="alarmParam.partMaxZa"
                            style="width: 100px;float: left;"
                            controls-position="right"
                            :min="0"
                            :max="255"></el-input-number>
                          <div style="width: 95px;float: left;text-align: right;padding-right: 10px">dz95:</div>
                          <el-input-number
                            v-model="alarmParam.partDz95"
                            style="width: 100px;float: left;"
                            controls-position="right"
                            :min="0"
                            :max="255"></el-input-number>
                        </div>
                      </div>
                    </div>
                  </el-tab-pane>
                </el-tabs>
              </el-col>
            </el-row>
          </div>
        </el-tab-pane>
        <el-tab-pane :label="$l('potentialFault.potentialFault','安全隐患记录')" name="three">
          <vm-table
            ref="faultTable"
            v-loading="faultLoading"
            :filter.sync="faultSearch"
            :url="faultUrl"
            @clear="clearFaultDateRange()">
            <template slot="adSearch">
              <div class="vm-separate">
                <vm-search :label="$l('maintWorkOrder.filter','关键字检索')">
                  <el-input v-model.trim="faultSearch.filter" :placeholder="$t('common.search')" clearable></el-input>
                </vm-search>
                <vm-search :label="$l('workOrder.faultTime','安全隐患时间')">
                  <el-date-picker
                    v-model="faultDateRange"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    type="datetimerange"
                    style="width: 100%"
                    :unlink-panels="true"
                    :range-separator="$t('common.to')"
                    :start-placeholder="$t('common.startDate')"
                    :end-placeholder="$t('common.endDate')"
                    :default-time="['00:00:00', '23:59:59']"
                    @change="handleFaultDateChange">
                  </el-date-picker>
                </vm-search>
              </div>
            </template>
            <el-table-column prop="faultCode" :label="$l('workOrder.faultNo','安全隐患代码')" width="100px"></el-table-column>
            <el-table-column prop="faultDesc" :label="$l('potentialFault.faultDesc','安全隐患描述')"></el-table-column>
            <el-table-column prop="startFloor" :label="$l('faultTemplate.startFloor','开始楼层')" width="80px"
                             align="center"></el-table-column>
            <el-table-column prop="endFloor" :label="$l('faultTemplate.endFloor','结束楼层')" width="80px"
                             align="center"></el-table-column>
            <el-table-column prop="runDistance" label="运行距离(m)" width="90px" align="center"></el-table-column>
            <el-table-column prop="vmax" label="最大速度(m/s)" width="105px" align="center"></el-table-column>
            <el-table-column prop="amax" label="最大加速度(m/s2)" width="120px" align="center"></el-table-column>
            <el-table-column prop="jmax" label="最大变加速度(m/s3)" width="130px" align="center"></el-table-column>
            <el-table-column prop="faultTime" :label="$l('workOrder.faultTime','安全隐患时间')" width="160px"
                             align="center"></el-table-column>
            <el-table-column :label="$t('common.operation')" width="70px" align="center">
              <template slot-scope="scope">
                <el-button v-if="scope.row.vibrationId" type="primary" @click="handleCurve2(scope.row.vibrationId)">
                  {{$t("vibrationData.curve")}}
                </el-button>
              </template>
            </el-table-column>
          </vm-table>
        </el-tab-pane>
      </el-tabs>
    </div>
    <vibration-curve ref="vibrationCurvePage"></vibration-curve>
  </el-dialog>
</template>
<script>
  import {saveAs} from "file-saver";
  import {collectFrequency, typeToArray} from "@/util/constant";
  import VibrationCurve from "@/views/elevator/VibrationCurve";
  import EocdMonitor from "@/views/elevator/EocdMonitor";

  const wsUrl = window.config.wsServerUrl;
  const reconnectInterval = 3000; // 重试间隔时间,3秒

  export default {
    components: {EocdMonitor, VibrationCurve},
    data() {
      return {
        eocdParamAuth: this.$auth(828),
        elevator: {
          id: 0,
          dtuCode: "",
          nodeCode: "",
          floor: [],
        },
        url: "",
        faultUrl: "",
        search: {
          filter: "",
          collectType: null,
          startTime: "",
          endTime: "",
        },
        faultSearch: {
          filter: "",
          startTime: "",
          endTime: "",
          elevatorId: this.$route.params.elevatorId,
        },
        faultLoading: false,
        faultDateRange: [],
        dateRange: [],
        activeName: "first",
        activeName2: "first",
        loading: false,
        adSearch: false,
        dialogVisible: false,
        currentPage: 1,
        filter: "",
        total: 0,
        tableData: [],
        createTime: "",
        tableData2: [],
        loading2: false,
        dtuState: "info",
        connectState: "",
        selectFrequencyValue: "",
        setCycleValue: "",
        setDoorCycleValue: "",
        setFrequencyValue: "",
        setTotalSwitch: false,
        setCollectSwitch: false,
        setAutoUploadSwitch: false,
        setDoorCollectSwitch: false,
        setAlarmFileSwitch: false,
        safetyHazardSwitch: false,
        safetyHazardRecord: false,
        frequencys: [],
        frequencyList: typeToArray(collectFrequency),
        alarmParam: {
          vend1: undefined,
          vend2: undefined,
          rollMaxZa: undefined,
          rollDz95: undefined,
          rollV: undefined,
          longA1Max: undefined,
          longDz95Max: undefined,
          longDz95: undefined,
          partMaxZa: undefined,
          partDz95: undefined,
        },
      };
    },
    mounted() {
      this.frequencys = collectFrequency;
    },
    methods: {
      handleClick(tab, event) {
        console.log(tab, event);
        if (tab.name == "first") {
          this.getList(1);
        }
        if (tab.name === "three") {
          this.getFaultList(1);
        }
      },
      clearFaultDateRange() {
        this.faultDateRange = [];
      },
      clearDateRange() {
        this.dateRange = [];
      },
      open(id, dtuCode, nodeCode) {
        this.dialogVisible = true;
        this.elevator.id = id;
        this.elevator.dtuCode = dtuCode;
        this.elevator.nodeCode = nodeCode;
        if (id > 0) {
          this.getList(1);
          this.$nextTick(() => {
            this.$refs.eocdMonitor.load(dtuCode, nodeCode);
          });
        }
      },
      getList(pageIndex) {
        this.loading = false;
        this.url = "vibrationDatas/elevator/web/" + this.elevator.id;
        this.$nextTick(() => {
          this.$refs.vmTable.getList(pageIndex);
        });
      },
      getFaultList(pageIndex) {
        this.faultLoading = false;
        this.faultUrl = "eocdFault/" + this.elevator.id;
        this.$nextTick(() => {
          this.$refs.faultTable.getList(pageIndex);
        });
      },
      handleFaultDateChange(dateRange) {
        if (dateRange) {
          this.faultSearch.startTime = dateRange[0];
          this.faultSearch.endTime = dateRange[1];
        } else {
          this.faultSearch.startTime = "";
          this.faultSearch.endTime = "";
        }
      },
      handleDateChange(dateRange) {
        if (dateRange) {
          this.search.startTime = dateRange[0];
          this.search.endTime = dateRange[1];
        } else {
          this.search.startTime = "";
          this.search.endTime = "";
        }
      },
      handleDown(row) {
        this.$api.getById("vibrationDatas", row.pkid).then(res => {
          let str = new Blob([res.data.dataContent], {type: "text/plain;charset=utf-8"});
          let fileName = "电梯_" + res.data.elevatorName + "_" + res.data.createdTime;
          saveAs(str, fileName + ".txt");
          this.loading = false;
        }).catch((error) => {
          this.loading = false;
          if (error.response) {
            this.$message.error(this.$t("common.tip.getDataError") + "," + error.response.data.message);
          }
        });
      },
      handleCurve(row) {
        this.$refs.vibrationCurvePage.open(row.pkid);
      },
      handleCurve2(vibrationId) {
        this.$refs.vibrationCurvePage.open(vibrationId);
      },
      handleSearch(event) {
        this.adSearch = false;
        this.filter = event;
        this.createTime = "";
        this.getList(1);
      },
      handleAdSearch() {
        this.filter = "";
        this.adSearch = true;
        this.getList(1);
      },
      handleAdd() {
        this.$refs.editPage.open(0);
      },
      changePage(pageIndex) {
        this.getList(pageIndex);
      },
      changePage2(pageIndex) {
        this.getList(pageIndex);
      },
      handleDialogClose() {
        this.dialogVisible = false;
        this.activeName = "first";
        this.tableData = [];
        this.tableData2 = [];
        this.selectFrequencyValue = "";
        this.setCycleValue = "";
        this.setDoorCycleValue = "";
        this.setFrequencyValue = "";
        this.setTotalSwitch = false;
        this.setCollectSwitch = false;
        this.setAutoUploadSwitch = false;
        this.setDoorCollectSwitch = false;
        this.setAlarmFileSwitch = false;
        this.$nextTick(() => {
          this.$refs.eocdMonitor.close();
        });
      },
      handleSearchEOCDData() {
        this.loading2 = true;
        let param = {
          dtuCode: this.elevator.dtuCode,
          nodeCode: this.elevator.nodeCode,
        };
        this.$http.get("/eocdOperate/searchCollect", param).then(res => {
          if (res.data.status == 0) {
            this.$message.error(res.data.err);
          } else {
            this.tableData2 = res.data.data;
          }
          this.loading2 = false;
        }).catch((error) => {
          this.loading2 = false;
        });
      },
      handleStartCollect() {
        //'0': 250采样频率   '1'：500采样频率
        if (this.selectFrequencyValue === "") {
          this.$message.error("请选择手动采集评率");
        } else {
          this.$confirm("确定手动开始采集?", this.$t("common.tips"), {
            confirmButtonText: this.$t("common.confirm"),
            cancelButtonText: this.$t("common.cancel"),
            type: "warning",
            customClass: "messageBox",
          }).then(() => {
            let param = {
              dtuCode: this.elevator.dtuCode,
              nodeCode: this.elevator.nodeCode,
              collectFrequency: this.selectFrequencyValue,
            };
            this.$http.get("/eocdOperate/startCollect", param).then(res => {
              if (res.data.status == 0) {
                this.$message.error(res.data.err);
              } else {
                this.$message.success(res.data.data);
              }
            }).catch((error) => {
            });
          });
        }
      },
      handleEndCollect() {
        this.$confirm("确定手动停止采集?", this.$t("common.tips"), {
          confirmButtonText: this.$t("common.confirm"),
          cancelButtonText: this.$t("common.cancel"),
          type: "warning",
          customClass: "messageBox",
        }).then(() => {
          let param = {
            dtuCode: this.elevator.dtuCode,
            nodeCode: this.elevator.nodeCode,
          };
          this.$http.get("/eocdOperate/endCollect", param).then(res => {
            if (res.data.status == 0) {
              this.$message.error(res.data.err);
            } else {
              this.$message.success(res.data.data);
            }
          }).catch((error) => {
          });
        });
      },
      handleClearCollect() {
        this.$confirm("确定清空采集记录?", this.$t("common.tips"), {
          confirmButtonText: this.$t("common.confirm"),
          cancelButtonText: this.$t("common.cancel"),
          type: "warning",
        }).then(() => {
          this.loading2 = true;
          let param = {
            dtuCode: this.elevator.dtuCode,
            nodeCode: this.elevator.nodeCode,
            collectTime: null,
            collectFrequency: null,
            collectType: null,
          };
          this.$http.get("/eocdOperate/clearCollect", param).then(res => {
            if (res.data.status == 0) {
              this.$message.error(res.data.err);
              this.loading2 = false;
            } else {
              this.$message.success(res.data.data);
              this.handleSearchEOCDData();
            }
          }).catch((error) => {
          });
        });
      },
      handleCancelCollect() {
        this.$confirm("确定撤销采集?", this.$t("common.tips"), {
          confirmButtonText: this.$t("common.confirm"),
          cancelButtonText: this.$t("common.cancel"),
          type: "warning",
        }).then(() => {
          this.loading2 = true;
          let param = {
            dtuCode: this.elevator.dtuCode,
            nodeCode: this.elevator.nodeCode,
          };
          this.$http.get("/eocdOperate/cancelCollect", param).then(res => {
            if (res.data.status == 0) {
              this.$message.error(res.data.err);
              this.loading2 = false;
            } else {
              this.$message.success(res.data.data);
              this.handleSearchEOCDData();
            }
          }).catch((error) => {
          });
        });
      },
      handleCalibration() {
        this.$confirm("确定校准?", this.$t("common.tips"), {
          confirmButtonText: this.$t("common.confirm"),
          cancelButtonText: this.$t("common.cancel"),
          type: "warning",
        }).then(() => {
          let param = {
            dtuCode: this.elevator.dtuCode,
            nodeCode: this.elevator.nodeCode,
          };
          this.$http.get("/eocdOperate/adjust", param).then(res => {
            if (res.data.status == 0) {
              this.$message.error(res.data.err);
            } else {
              this.$message.success(res.data.data);
            }
          }).catch((error) => {
          });
        });
      },
      handleSetParam(paramNo) {
        if (paramNo == 999) {
          if (this.alarmParam.vend1 === undefined
            || this.alarmParam.vend2 === undefined
            || this.alarmParam.rollMaxZa === undefined
            || this.alarmParam.rollDz95 === undefined
            || this.alarmParam.rollV === undefined
            || this.alarmParam.longA1Max === undefined
            || this.alarmParam.longDz95Max === undefined
            || this.alarmParam.longDz95 === undefined
            || this.alarmParam.partMaxZa === undefined
            || this.alarmParam.partDz95 === undefined
          ) {
            this.$message.error("报警参数设置不能为空");
            return;
          }
          this.$confirm("确定设置报警参数?", this.$t("common.tips"), {
            confirmButtonText: this.$t("common.confirm"),
            cancelButtonText: this.$t("common.cancel"),
            type: "warning",
          }).then(() => {
            let param = {
              dtuCode: this.elevator.dtuCode,
              nodeCode: this.elevator.nodeCode,
              alarmParam: this.alarmParam,
            };
            this.$http.post("/eocdOperate/setAlarmParam/" + this.elevator.dtuCode + "/" + this.elevator.nodeCode, this.alarmParam).then(res => {
              if (res.data.status == 0) {
                this.$message.error(res.data.err);
              } else {
                this.$message.success(res.data.data);
              }
            }).catch((error) => {
            });
          });
        } else {
          let paramValue = null;
          if (paramNo == 0) {
            paramValue = this.setTotalSwitch ? 1 : 0;
          }
          if (paramNo == 1) {
            paramValue = this.setCollectSwitch ? 1 : 0;
          }
          if (paramNo == 2) {
            if (this.setCycleValue === "") {
              this.$message.error("请填写采集周期");
              return;
            }
            paramValue = this.setCycleValue;
          }
          if (paramNo == 3) {
            if (this.setFrequencyValue === "") {
              this.$message.error("请选择采集频率");
              return;
            }
            paramValue = this.setFrequencyValue;
          }
          if (paramNo == 4) {
            paramValue = this.setAutoUploadSwitch ? 1 : 0;
          }
          if (paramNo == 5) {
            paramValue = this.setDoorCollectSwitch ? 1 : 0;
          }
          if (paramNo == 6) {
            if (this.setDoorCycleValue === "") {
              this.$message.error("开关门自动采集周期");
              return;
            }
            paramValue = this.setDoorCycleValue;
          }
          if (paramNo == 7) {
            paramValue = this.setAlarmFileSwitch ? 1 : 0;
          }
          if (paramNo === 50) {
            paramValue = this.safetyHazardSwitch ? 1 : 0;
          }
          if (paramNo === 51) {
            paramValue = this.safetyHazardRecord ? 1 : 0;
          }
          if (paramValue != null) {
            this.$confirm("确定设置参数?", this.$t("common.tips"), {
              confirmButtonText: this.$t("common.confirm"),
              cancelButtonText: this.$t("common.cancel"),
              type: "warning",
            }).then(() => {
              let param = {
                dtuCode: this.elevator.dtuCode,
                nodeCode: this.elevator.nodeCode,
                paramNo: paramNo,
                paramValue: paramValue,
              };
              this.$http.get("/eocdOperate/setParam", param).then(res => {
                if (res.data.status == 0) {
                  this.$message.error(res.data.err);
                } else {
                  this.$message.success(res.data.data);
                }
              }).catch((error) => {
              });
            });
          }
        }
      },
      handleReadParam(paramNo) {
        if (paramNo == 999) {
          let param = {
            dtuCode: this.elevator.dtuCode,
            nodeCode: this.elevator.nodeCode,
          };
          this.$http.get("/eocdOperate/readAlarmParam", param).then(res => {
            if (res.data.status == 0) {
              this.$message.error(res.data.err);
            } else {
              this.alarmParam.vend1 = res.data.data.vend1;
              this.alarmParam.vend2 = res.data.data.vend2;
              this.alarmParam.rollMaxZa = res.data.data.rollMaxZa;
              this.alarmParam.rollDz95 = res.data.data.rollDz95;
              this.alarmParam.rollV = res.data.data.rollV;
              this.alarmParam.longA1Max = res.data.data.longA1Max;
              this.alarmParam.longDz95Max = res.data.data.longDz95Max;
              this.alarmParam.longDz95 = res.data.data.longDz95;
              this.alarmParam.partMaxZa = res.data.data.partMaxZa;
              this.alarmParam.partDz95 = res.data.data.partDz95;
              this.$message.success("报警参数读取成功");
            }
          }).catch((error) => {
          });
        } else {
          let param = {
            dtuCode: this.elevator.dtuCode,
            nodeCode: this.elevator.nodeCode,
            paramNo: paramNo,
          };
          this.$http.get("/eocdOperate/readParam", param).then(res => {
            if (res.data.status == 0) {
              this.$message.error(res.data.err);
            } else {
              let data = res.data.data;
              if (data.paramNo === 0) {
                this.setTotalSwitch = data.paramValue == 1;
              }
              if (data.paramNo === 1) {
                this.setCollectSwitch = data.paramValue == 1;
              }
              if (data.paramNo === 2) {
                this.setCycleValue = data.paramValue + "";
              }
              if (data.paramNo === 3) {
                this.setFrequencyValue = this.frequencys[data.paramValue];
              }
              if (data.paramNo === 4) {
                this.setAutoUploadSwitch = data.paramValue == 1;
              }
              if (data.paramNo === 5) {
                this.setDoorCollectSwitch = data.paramValue == 1;
              }
              if (data.paramNo === 6) {
                this.setDoorCycleValue = data.paramValue + "";
              }
              if (data.paramNo === 7) {
                this.setAlarmFileSwitch = data.paramValue == 1;
              }
              if (data.paramNo === 50) {
                this.safetyHazardSwitch = data.paramValue == 1;
              }
              if (data.paramNo === 51) {
                this.safetyHazardRecord = data.paramValue == 1;
              }
              this.$message.success("参数读取成功！");
            }
          }).catch((error) => {
          });
        }
      },
      handleCollectDown(row) {
        this.$api.getData("vibrationDatas/" + this.elevator.dtuCode + "/" + this.elevator.nodeCode + "/" + row.collectTime).then(res => {
          if (res.data.message != null) {
            let param = {
              dtuCode: this.elevator.dtuCode,
              nodeCode: this.elevator.nodeCode,
              collectTime: row.collectTime,
              collectFrequency: row.collectFrequency,
              collectType: row.collectType,
            };
            this.$http.get("/eocdOperate/collectDown", param).then(res => {
              if (res.data.status == 0) {
                this.$message.error(res.data.err);
              }
            }).catch((error) => {
            });
            this.$message.error(res.data.message);
          } else {
            let str = new Blob([res.data.fileContent], {type: "text/plain;charset=utf-8"});
            let fileName = res.data.fileName;
            saveAs(str, fileName + ".txt");
          }
        }).catch(() => {
          this.$message.error(this.$t("common.tip.getDataError"));
        });
      },
      handleCollectDelete(row) {
        this.$confirm("确定清空该采集记录?", this.$t("common.tips"), {
          confirmButtonText: this.$t("common.confirm"),
          cancelButtonText: this.$t("common.cancel"),
          type: "warning",
        }).then(() => {
          this.loading2 = true;
          let param = {
            dtuCode: this.elevator.dtuCode,
            nodeCode: this.elevator.nodeCode,
            collectTime: row.collectTime,
            collectFrequency: row.collectFrequency,
            collectType: row.collectType,
          };
          this.$http.get("/eocdOperate/clearCollect", param).then(res => {
            if (res.data.status == 0) {
              this.$message.error(res.data.err);
              this.loading2 = false;
            } else {
              this.$message.success(res.data.data);
              this.handleSearchEOCDData();
            }
          }).catch((error) => {
          });
        });
      },
    },
  };
</script>

<style lang="scss" scoped>
</style>
