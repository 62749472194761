<template>
  <el-dialog :close-on-click-modal="false" :visible.sync="dialogVisible" :title="$t('tpLink.playBack')" width="580px"
    top="3vh">
    <el-form ref="formValidate" class="form" label-width="180px">
      <el-form-item :label="$t('common.pleaseSelect')">
        <el-date-picker v-model="selectedDate" format="yyyy-MM-dd" value-format="yyyy-MM-dd" type="date"
          @change="handleDateChange">
        </el-date-picker>
      </el-form-item>
    </el-form>
    <span slot="footer">
      <el-button type="primary" @click="confirm()">{{ $t("common.confirm") }}</el-button>
      <el-button @click="cancel()">{{ $t("common.cancel") }}</el-button>
    </span>
  </el-dialog>
</template>

<script>

export default {
  data() {
    return {
      dialogVisible: false,
      selectedDate: new Date(),
      playBackUrl: "",
      devId: "",
    };
  },
  computed() {

  },
  methods: {
    open(devId) {
      this.devId = devId;
      this.dialogVisible = true;
    },
    handleDateChange(selectedDate) {
      this.selectedDate = new Date(selectedDate);
    },
    confirm() {
      //this.dialogVisible = false;
      let year = this.selectedDate.getFullYear();
      let month = this.selectedDate.getMonth() + 1;
      if (month < 10) {
        month = "0" + month;
      }
      let date = this.selectedDate.getDate();
      let day = year + "-" + month + "-" + date;

      this.$http.get("tpLink/getPlayBackUrl/" + this.devId + "/" + day).then(res => {
        this.playBackUrl = res.data;
        window.open(this.playBackUrl, "_blank");
      }).catch(err => {
        this.$message.success(this.$t("common.tip.operationSuccess"));
      });
    },
    cancel() {
      this.dialogVisible = false;
      //this.selectedDate=new Date();
    },
  },
};
</script>

<style lang="scss" scoped></style>
