<template>
  <el-dialog
    class="select-dialog"
    :title="$t('common.select')+$t('route.qualitySupervision')"
    :visible.sync="dialogVisible"
    width="80%"
    top="5vh"
    append-to-body>
    <vm-table
      ref="vmTable"
      v-loading="loading"
      :filter.sync="search"
      url="quantities">
      <template slot="adSearch">
        <vm-search :label="$l('maintWorkOrder.filter','关键字检索')">
          <el-input v-model.trim="search.filter" :placeholder="$t('common.search')" clearable></el-input>
        </vm-search>
      </template>
      <el-table-column prop="no" :label="$t('quantity.no')" min-width="40"></el-table-column>
      <el-table-column prop="name" :label="$t('quantity.name')"></el-table-column>
      <el-table-column prop="address" :label="$t('quantity.address')"></el-table-column>
      <el-table-column :label="$t('common.operation')" width="100" align="center">
        <template slot-scope="scope">
          <el-button type="text" class="successBtn" @click="handleSelect(scope.row)">{{$t("common.select")}}</el-button>
        </template>
      </el-table-column>
    </vm-table>
  </el-dialog>
</template>

<script>

  const moduleName = "quantities";

  export default {
    data() {
      return {
        dialogVisible: false,
        loading: true,
        search: {
          filter: "",
        },
      };
    },
    methods: {
      open() {
        this.dialogVisible = true;
        this.loading = false;
        this.$nextTick(() => {
          this.getList(1);
        });
      },
      getList(pageNum) {
        this.$refs.vmTable.getList(pageNum);
      },
      handleSelect(row) {
        this.$emit("select", row);
        this.dialogVisible = false;
      },
    },
  };
</script>

<style lang="scss" scoped>
</style>
