<template>
  <div>
    <el-form
      ref="form"
      class="form"
      :label-width="$l('elevator.labelWidth', '120px')"
      :model="insh"
      :rules="ruleValidate">
      <div class="vm-separate">
        <el-form-item :label="$t('deviceGateway.dtuCode')" prop="inshnId">
          <el-input v-model="insh.inshnId" :placeholder="$t('common.pleaseEnter')"></el-input>
        </el-form-item>
        <el-form-item :label="$t('deviceGateway.infraredType')" prop="inshnIo">
          <el-select
            v-model="insh.inshnIo"
            :placeholder="$t('common.pleaseSelect')"
            style="width: 100%">
            <el-option
              v-for="item in inshnIoList"
              :key="item.value"
              :label="item.label"
              :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('deviceGateway.elevatorSpeed')" prop="inshnSpeed">
          <el-input v-model.trim="insh.inshnSpeed" type="number" :placeholder="$t('common.pleaseEnter')"></el-input>
        </el-form-item>
      </div>
      <div class="vm-separate">
        <el-form-item :label="$t('deviceGateway.carDoorType')" prop="inshnDoorType">
          <el-select
            v-model="insh.inshnDoorType"
            :placeholder="$t('common.pleaseSelect')"
            style="width: 100%">
            <el-option
              v-for="item in inshnDoorTypeList"
              :key="item.value"
              :label="item.label"
              :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$l('deviceGateway.judgmentsNumber','异常判断次数')" prop="inshnDoorCnt">
          <el-input v-model.trim="insh.inshnDoorCnt" type="number" :placeholder="$t('common.pleaseEnter')"></el-input>
        </el-form-item>
        <el-form-item :label="$l('deviceGateway.openDoorTime','开门时间')" prop="inshnDoorTime">
          <el-input v-model.trim="insh.inshnDoorTime" type="number" :placeholder="$t('common.pleaseEnter')"></el-input>
        </el-form-item>
      </div>
      <div class="vm-separate">
        <el-form-item :label="$l('deviceGateway.horizontalSpacing','平层间距')" prop="inshnNextTime">
          <el-input v-model.trim="insh.inshnNextTime" type="number" :placeholder="$t('common.pleaseEnter')"></el-input>
        </el-form-item>
        <el-form-item :label="$t('deviceGateway.simICCID')" prop="iccid">
          <el-input v-model.trim="insh.iccid" :placeholder="$t('common.pleaseEnter')"></el-input>
        </el-form-item>
        <el-form-item :label="$l('user.phone','手机号码')" prop="inshnRegtel">
          <el-input v-model.trim="insh.inshnRegtel" :placeholder="$t('common.pleaseEnter')"></el-input>
        </el-form-item>
      </div>
      <div class="vm-separate">
        <el-form-item :label="$l('deviceGateway.sensor','传感器')" prop="inshnFiltering">
          <el-select
            v-model="insh.inshnFiltering"
            :placeholder="$t('common.pleaseSelect')"
            style="width: 100%">
            <el-option
              v-for="item in inshnFilteringList"
              :key="item.value"
              :label="item.label"
              :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$l('deviceGateway.networkFormat','网络制式')" prop="inshnNetctype">
          <el-select
            v-model="insh.inshnNetctype"
            :placeholder="$t('common.pleaseSelect')"
            style="width: 100%">
            <el-option
              v-for="item in inshnNetctypeList"
              :key="item.value"
              :label="item.label"
              :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <div></div>
      </div>
    </el-form>
  </div>
</template>
<script>

  export default {
    components: {},
    // eslint-disable-next-line vue/require-prop-types
    props:["insh"],
    data() {
      return {
        inshnIoList: [{value: 0, label: this.$l("deviceGateway.close","常闭")}, {value: 1, label: this.$l("deviceGateway.open","常开")}],
        inshnDoorTypeList:[{value: 0, label: this.$l("deviceGateway.singleDoor","单门")}, {value: 1, label: this.$l("deviceGateway.doubleDoor","双门")}, {value: 2, label: this.$l("deviceGateway.smoke","单门+烟感")}, {value: 3, label:this.$l("deviceGateway.openDoorInPlace","单门+开门到位")}],
        inshnFilteringList: [{value: 10, label: this.$l("deviceGateway.other","其他")},  {value: 3, label: this.$l("deviceGateway.uModel","U型")}],
        inshnNetctypeList:[{value: 0, label: this.$l("deviceGateway.wired","有线")}, {value: 1, label: "2G"}, {value: 2, label: "3G"}, {value: 3, label: "4G"}],
        deviceGatewayInshVo: {
          id:0,
          inshnId: "",
          inshnIo: 0,
          inshnSpeed: 2.0,
          inshnDoorType: 0,
          inshnDoorCnt: 8,
          inshnDoorTime: 10,
          inshnNextTime: 123,
          inshnRegtel: "",
          iccid: "",
          inshnFiltering: 3,
          inshnNetctype: ""
        },
        ruleValidate: {
          inshnId: [{required: true, message: this.$t("common.tip.notNull"), trigger: "blur"}],
          inshnIo: [{required: true, message: this.$t("common.tip.notNull"), trigger: "blur"}],
          inshnSpeed: [{required: true,message: this.$t("common.tip.notNull"), trigger: "blur"}],
          inshnDoorType: [{required: true,  message: this.$t("common.tip.notNull"), trigger: "blur"}],
          inshnDoorCnt: [{required: true, message: this.$t("common.tip.notNull"), trigger: "blur"}],
          inshnDoorTime: [{required: true, message: this.$t("common.tip.notNull"), trigger: "blur"}],
          inshnNextTime: [{required: true, message: this.$t("common.tip.notNull"), trigger: "blur"}],
          inshnRegtel: [{required: true,pattern: /^(0|86|17951)?(13[0-9]|15[012356789]|166|17[3678]|18[0-9]|19[0-9]|14[57])[0-9]{8}$/, message: this.$t("user.tip.phone"), trigger: "blur"}],
          inshnFiltering: [{required: true, message: this.$t("common.tip.notNull"), trigger: "blur"}],
          inshnNetctype: [{required: true,message: this.$t("common.tip.notNull"), trigger: "blur"}],
        }
      };
    },
    methods: {
      getData() {
        return this.insh;
      },
    },
  };
</script>
<style type="text/css">

</style>
