<template>
  <el-dialog
    class="select-dialog"
    :title="$t('common.select') + ' ' + $t('faultTemplate.faultTemplate')"
    :visible.sync="dialogVisible"
    width="80%"
    top="6vh"
    append-to-body
    @close="onDialogClose">
    <vm-table
      ref="vmTable"
      v-loading="loading"
      :filter.sync="search"
      url="faults">
      <template slot="adSearch">
        <vm-search :label="$l('maintWorkOrder.filter','关键字检索')">
          <el-input v-model.trim="search.filter" :placeholder="$t('common.search')" clearable></el-input>
        </vm-search>
      </template>
      <el-table-column prop="faultNO" :label="$t('faultTemplate.no')"></el-table-column>
      <el-table-column prop="companyName" :label="$t('elevator.manufactured')"></el-table-column>
      <el-table-column prop="name" :label="$t('faultTemplate.name')"></el-table-column>
      <el-table-column :label="$t('common.operation')" width="100" align="center">
        <template slot-scope="scope">
          <el-button type="text" class="successBtn" @click="handleSelect(scope.row)">{{$t("common.select")}}</el-button>
        </template>
      </el-table-column>
    </vm-table>
  </el-dialog>
</template>
<script>

  export default {
    data() {
      return {
        dialogVisible: false,
        loading: false,
        faultId: null,
        search: {
          filter: "",
        },
      };
    },
    methods: {
      getList(pageNum) {
        this.loading = false;
        this.$refs.vmTable.getList(pageNum);
      },
      open() {
        this.dialogVisible = true;
        this.$nextTick(() => {
          this.getList(1);
        });
      },
      handleSelect(row) {
        this.$emit("select", row);
        this.dialogVisible = false;
      },
      onDialogClose() {
        this.$copy(this.$data, this.$options.data.call(this));
      },
      changePage(pageIndex) {
        this.getList(pageIndex);
      },
    },
  };
</script>
<style lang="scss" scoped>
</style>
