<template>
  <el-dialog class="select-dialog" :title="$t('common.select') + ' ' + $t('route.tpLinkVideo')"
    :visible.sync="dialogVisible" width="1100px" top="2vh" append-to-body>
    <vm-table ref="vmTable" v-loading="loading" :filter.sync="search" url="tpLink">
      <template slot="adSearch">
        <vm-search :label="$t('dayWeekMonthManger.filter')">
          <el-input v-model.trim="search.filter" :placeholder="$t('common.pleaseEnter')" clearable></el-input>
        </vm-search>
        <vm-search :label="$t('tpLink.deviceStatus')">
          <el-select v-model.trim="search.deviceStatus" :placeholder="$t('common.pleaseSelect')" style="width: 100%"
            clearable>
            <el-option v-for="item in deviceStatusList" :key="item.value" :label="item.label"
              :value="item.value"></el-option>
          </el-select>
        </vm-search>
      </template>
      <!-- <el-table-column prop="devId" :label="$l('tpLink.deviceName', 'VMSID')" width="80"
        align="center"></el-table-column> -->
      <el-table-column prop="deviceName" :label="$t('tpLink.deviceName')" width="150px"></el-table-column>
      <el-table-column prop="elevatorName" :label="$t('tpLink.boundElevator')"></el-table-column>
      <el-table-column prop="deviceStatus" :label="$t('tpLink.deviceStatus')" width="80px">
        <template slot-scope="scope">
          <el-tag class="normal" v-if="scope.row.deviceStatus === 1">{{ $t("monitor.online") }}</el-tag>
          <el-tag class="abnormal" v-else>{{ $t("monitor.offline") }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="deviceType" :label="$t('tpLink.deviceType',)" width="80px"></el-table-column>
      <el-table-column prop="deviceModel" :label="$t('tpLink.deviceModel')" width="150px"></el-table-column>
      <el-table-column prop="ethernet" :label="$t('tpLink.mac')" width="150px"></el-table-column>
      <el-table-column :label="$t('dayWeekMonthManger.operate')" width="60" align="center">
        <template slot-scope="scope">
          <el-button type="text" style="color: #00C291;" @click="handleSelect(scope.row)">{{ $t("common.select")
            }}</el-button>
        </template>
      </el-table-column>
    </vm-table>
  </el-dialog>
</template>

<script>

export default {
  data() {
    return {
      dialogVisible: false,
      loading: true,
      refreshLoading: false,
      search: {
        filter: "",
        deviceStatus: "",
      },
      deviceStatusList: [
        { value: 1, label: this.$t("monitor.online") },
        { value: 0, label: this.$t("monitor.offline") },
      ],
    };
  },
  methods: {
    open() {
      this.dialogVisible = true;
      this.$nextTick(() => {
        this.getList(1);
      });
    },
    getList(pageNum) {
      this.loading = false;
      this.$refs.vmTable.getList(pageNum);
    },
    handleSelect(row) {
      this.$emit("select", row);
      this.dialogVisible = false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
