<template>
  <el-dialog
    :title="title"
    :close-on-click-modal="false"
    :visible.sync="dialogVisible"
    width="1500px"
    top="2vh"
    class="small-padding"
    @close="onDialogClose">
    <div v-loading="contentLoading" class="body">
      <el-form
        ref="formValidate"
        class="form"
        :label-width="$l('elevator.labelWidth', '120px')"
        :model="elevator"
        :rules="ruleValidate">
        <el-tabs v-model="activeTab" @tab-click="tabClick">
          <el-tab-pane :label="$t('elevator.baseInfo')" name="first">
            <div class="vm-separate">
              <el-form-item :label="$t('elevator.elevatorType')" prop="productTypeId">
                <el-select
                  v-model="elevator.productTypeId"
                  :placeholder="$t('elevator.elevatorType')"
                  style="width: 100%">
                  <el-option
                    v-for="item in productTypeList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item :label="$t('elevator.name')" prop="name">
                <el-input v-model="elevator.name" :placeholder="$t('common.pleaseEnter')"></el-input>
              </el-form-item>
              <el-form-item :label="$t('elevator.model')" prop="model">
                <el-input v-model="elevator.model" :placeholder="$t('common.pleaseEnter')"></el-input>
              </el-form-item>
            </div>
            <div class="vm-separate">
              <el-form-item :label="$t('elevator.brand')" prop="brand">
                <el-input v-model="elevator.brand" :placeholder="$t('common.pleaseEnter')" clearable></el-input>
              </el-form-item>
              <el-form-item :label="$t('elevator.whereUsed')" prop="applyPlaceId">
                <el-select v-model="elevator.applyPlaceId" :placeholder="$t('elevator.whereUsed')" style="width: 100%">
                  <el-option
                    v-for="item in placeList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item :label="$t('elevator.registerCode')">
                <el-input v-model.trim="elevator.regNo" :placeholder="$t('common.pleaseEnter')"></el-input>
              </el-form-item>
            </div>
            <div class="vm-separate">
              <el-form-item :label="$t('elevator.exFactoryNo')" prop="factoryNo">
                <el-input v-model.trim="elevator.factoryNo" :placeholder="$t('common.pleaseEnter')"></el-input>
              </el-form-item>
              <el-form-item :label="$t('elevator.controlCabinetLeaveNo')" prop="controlCabinetLeaveNo">
                <el-input
                  v-model.trim="elevator.controlCabinetLeaveNo"
                  :placeholder="$t('common.pleaseEnter')"></el-input>
              </el-form-item>
              <el-form-item :label="$t('elevator.registerCode1')" prop="deviceId">
                <el-input v-model.trim="elevator.deviceId" :placeholder="$t('common.pleaseEnter')"></el-input>
              </el-form-item>
            </div>
            <div class="vm-separate">
              <el-form-item :label="$t('elevator.exFactoryDate')" prop="factoryDate">
                <el-date-picker
                  v-model="elevator.factoryDate"
                  type="date"
                  :placeholder="$t('common.pleaseSelect')"
                  style="width: 100%"
                  value-format="yyyy-MM-dd"></el-date-picker>
              </el-form-item>
              <el-form-item :label="$t('elevator.installationDate')" prop="installDate">
                <el-date-picker
                  v-model="elevator.installDate"
                  type="date"
                  :placeholder="$t('common.pleaseSelect')"
                  style="width: 100%"
                  value-format="yyyy-MM-dd"></el-date-picker>
              </el-form-item>
              <el-form-item :label="$t('elevator.serviceDate')" prop="useDate">
                <el-date-picker
                  v-model="elevator.useDate"
                  type="date"
                  :placeholder="$t('common.pleaseSelect')"
                  style="width: 100%"
                  value-format="yyyy-MM-dd"></el-date-picker>
              </el-form-item>
            </div>
            <div class="vm-separate">
              <el-form-item :label="$t('elevator.useStatus')">
                <el-radio-group v-model="elevator.useState">
                  <el-radio :label="1">{{ $t("elevator.useStatus_1") }}</el-radio>
                  <el-radio :label="2">{{ $t("elevator.useStatus_2") }}</el-radio>
                  <el-radio :label="3">{{ $t("elevator.useStatus_3") }}</el-radio>
                  <el-radio :label="4">{{ $t("elevator.useStatus_4") }}</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item :label="$t('elevator.manufactured')" prop="createComId">
                <el-input v-model="elevator.createComName" :placeholder="$t('common.pleaseSelect')" readonly>
                  <el-button slot="append" icon="el-icon-search" @click="$refs.selectCreateCom.open()"></el-button>
                </el-input>
                <company-select ref="selectCreateCom" @select="onSelectCreateCom"></company-select>
              </el-form-item>
              <el-form-item :label="$t('elevator.qualityOrgan')" prop="quantityOrganId">
                <el-input v-model="elevator.quantityOrganName" :placeholder="$t('common.pleaseSelect')" readonly>
                  <el-button slot="append" icon="el-icon-search" @click="$refs.selectQuantityOrgan.open()"></el-button>
                </el-input>
                <quantity-select ref="selectQuantityOrgan" @select="onSelectQuantityOrgan"></quantity-select>
              </el-form-item>
            </div>
            <div class="vm-separate">
              <el-form-item :label="$t('elevator.installationEnterprise')" prop="installComId">
                <el-input v-model="elevator.installComName" :placeholder="$t('common.pleaseSelect')" readonly>
                  <el-button slot="append" icon="el-icon-search" @click="$refs.selectInstallCom.open()"></el-button>
                </el-input>
                <company-select ref="selectInstallCom" @select="onSelectInstallCom"></company-select>
              </el-form-item>
              <el-form-item :label="$t('elevator.reconstructed')" prop="remakeComId">
                <el-input v-model="elevator.remakeComName" :placeholder="$t('common.pleaseSelect')" readonly>
                  <el-button slot="append" icon="el-icon-search" @click="$refs.selectRemakeCom.open()"></el-button>
                </el-input>
                <company-select ref="selectRemakeCom" @select="onSelectRemakeCom"></company-select>
              </el-form-item>
              <el-form-item :label="$t('elevator.useUnit')" prop="useUnitId">
                <el-input v-model="elevator.useUnitName" :placeholder="$t('common.pleaseSelect')" readonly>
                  <el-button
                    slot="append"
                    icon="el-icon-search"
                    @click="$refs.selectUseUnitProperty.open()"></el-button>
                </el-input>
                <use-unit-select ref="selectUseUnitProperty" @select="onSelectUseUnit"></use-unit-select>
              </el-form-item>
            </div>

            <div class="vm-separate">
              <el-form-item :label="$t('elevator.maintenanced')" prop="maintComId">
                <el-input v-model="elevator.maintComName" :placeholder="$t('common.pleaseSelect')" readonly>
                  <el-button slot="append" icon="el-icon-search" @click="$refs.selectMaintCom.open()"></el-button>
                </el-input>
                <company-select ref="selectMaintCom" @select="onSelectMaintCom"></company-select>
              </el-form-item>
              <el-form-item :label="$t('elevator.maintWorker') + '1'" prop="maintEmpId">
                <el-input v-model="elevator.maintEmpName" :placeholder="$t('common.pleaseSelect')" readonly>
                  <el-button slot="append" icon="el-icon-search" @click="selectMaintEmp()"></el-button>
                </el-input>
                <user-select
                  ref="onSelectMaintEmp"
                  :maint-worker-com-id="elevator.maintComId"
                  @select="onSelectMaintEmp"></user-select>
              </el-form-item>
              <el-form-item :label="$t('elevator.maintWorker') + '2'" prop="maintEmpId1">
                <el-input v-model="elevator.maintEmpName1" :placeholder="$t('common.pleaseSelect')" readonly>
                  <el-button slot="append" icon="el-icon-search" @click="selectMaintEmp1()"></el-button>
                </el-input>
                <user-select
                  ref="onSelectMaintEmp1"
                  :maint-worker-com-id="elevator.maintComId"
                  @select="onSelectMaintEmp1"></user-select>
              </el-form-item>
            </div>
            <div class="vm-separate">
              <el-form-item :label="$t('elevator.propertyCom')" prop="elevatorPropertyComId">
                <el-input v-model="elevator.elevatorPropertyComName" :placeholder="$t('common.pleaseSelect')" readonly>
                  <el-button slot="append" icon="el-icon-search" @click="$refs.selectPropertyCom.open()"></el-button>
                </el-input>
                <company-select ref="selectPropertyCom" @select="onSelectPropertyCom"></company-select>
              </el-form-item>
              <el-form-item :label="$t('elevator.safetyOfficer')" prop="safetyOfficerId">
                <el-input
                  v-model="elevator.safetyOfficerName"
                  :placeholder="$t('common.pleaseSelect')"
                  clearable
                  @clear="propertyEmpClear">
                  <el-button slot="append" icon="el-icon-search" @click="selectPropertyComEmp()"></el-button>
                </el-input>
                <user-select
                  ref="onSelectPropertyEmp"
                  :company-id="elevator.elevatorPropertyComId"
                  @select="onSelectPropertyEmp"></user-select>
              </el-form-item>
              <el-form-item :label="$t('elevator.safetyDirector')" prop="safetyDirectorId">
                <el-input
                  v-model="elevator.safetyDirectorName"
                  :placeholder="$t('common.pleaseSelect')"
                  clearable
                  @clear="propertyEmp1Clear">
                  <el-button slot="append" icon="el-icon-search" @click="selectPropertyComEmp1()"></el-button>
                </el-input>
                <user-select
                  ref="onSelectPropertyEmp1"
                  :company-id="elevator.elevatorPropertyComId"
                  @select="onSelectPropertyEmp1"></user-select>
              </el-form-item>
            </div>
            <div class="vm-separate">
              <el-form-item :label="$t('elevator.safetyMainer')" prop="safetyMainerId">
                <el-input
                  v-model="elevator.safetyMainerName"
                  :placeholder="$t('common.pleaseSelect')"
                  clearable
                  @clear="propertyEmp2Clear">
                  <el-button slot="append" icon="el-icon-search" @click="selectPropertyComEmp2()"></el-button>
                </el-input>
                <user-select
                  ref="onSelectPropertyEmp2"
                  :company-id="elevator.elevatorPropertyComId"
                  @select="onSelectPropertyEmp2"></user-select>
              </el-form-item>
              <template v-if="inspectionAuth">
                <el-form-item :label="$t('route.checkTemplates')">
                  <el-input
                    v-model="elevator.inspectionTemplateName"
                    clearable
                    :placeholder="$t('common.pleaseSelect')"
                    @clear="clearInspectionTemplateInfo">
                    <el-button
                      slot="append"
                      icon="el-icon-search"
                      @click="$refs.checkTemplateSelect.open()"></el-button>
                  </el-input>
                  <check-template-select
                    ref="checkTemplateSelect"
                    @select="checkTemplateSelect"></check-template-select>
                </el-form-item>
                <el-form-item>
                  <el-checkbox v-model="elevator.isAutoReport">
                    {{ $t("elevator.autoGenerate") }}
                  </el-checkbox>
                </el-form-item>
              </template>
              <div v-if="!inspectionAuth" style="flex: 2"></div>
            </div>
            <div class="vm-separate">
              <el-form-item :label="$t('elevator.agent')" prop="agentId">
                <el-input v-model="elevator.agentName" :placeholder="$t('common.pleaseSelect')" readonly>
                  <el-button slot="append" icon="el-icon-search" @click="$refs.selectAgent.open()"></el-button>
                </el-input>
                <company-select ref="selectAgent" @select="onSelectAgentCom"></company-select>
              </el-form-item>
              <el-form-item :label="$t('elevator.salesman')" prop="salesmanId">
                <el-input
                  v-model="elevator.salesman"
                  :placeholder="$t('common.pleaseSelect')"
                  clearable
                  @clear="salesmanClear">
                  <el-button slot="append" icon="el-icon-search" @click="$refs.onSelectSalesman.open()"></el-button>
                </el-input>
                <user-select
                  ref="onSelectSalesman"
                  :company-id="elevator.orgId"
                  @select="onSelectSalesman"></user-select>
              </el-form-item>
              <el-form-item :label="$t('elevator.salesmanTel')" prop="salesmanTel">
                <el-input
                  v-model.trim="elevator.salesmanTel"
                  disabled=""
                  :placeholder="$t('common.pleaseEnter')"></el-input>
              </el-form-item>
            </div>
            <div class="vm-separate">
              <el-form-item :label="$t('elevator.joinStatus')">
                <el-switch
                  v-model="elevator.inNetState"
                  :active-text="$t('common.yes')"
                  :inactive-text="$t('common.no')"></el-switch>
              </el-form-item>
              <el-form-item :label="$t('elevator.joinDate')" prop="inNetDate">
                <el-date-picker
                  v-model="elevator.inNetDate"
                  type="date"
                  :placeholder="$t('common.pleaseSelect')"
                  style="width: 100%"
                  value-format="yyyy-MM-dd"></el-date-picker>
              </el-form-item>
              <el-form-item
                v-if="userName === 'superAdmin' || userName === 'admin'"
                :label="$t('elevator.monitorTime')"
                prop="monitorLimit">
                <el-input
                  v-model="elevator.monitorLimit"
                  type="number"
                  :placeholder="$t('common.pleaseEnter')"></el-input>
              </el-form-item>
              <div v-else></div>
            </div>
            <el-divider content-position="left">{{ $t("elevator.parameters") }}</el-divider>
            <div class="vm-separate">
              <el-form-item :label="$t('elevator.ratedSpeedOfElevator')" prop="ratedSpeed" :rules="$rule.notNull">
                <el-input
                  v-model="elevator.ratedSpeed"
                  type="number"
                  :placeholder="$t('common.pleaseEnter')"></el-input>
              </el-form-item>
              <el-form-item :label="$t('elevator.ratedLoadOfElevator')" prop="ratedLoad">
                <el-input v-model="elevator.ratedLoad" type="number" :placeholder="$t('common.pleaseEnter')"></el-input>
              </el-form-item>
              <div></div>
            </div>
            <el-divider content-position="left"></el-divider>
            <div class="vm-separate">
              <el-form-item :label="$t('elevator.buildingNo')" prop="buildingNo">
                <el-input v-model="elevator.buildingNo" :placeholder="$t('common.pleaseEnter')"></el-input>
              </el-form-item>
              <el-form-item :label="$t('elevator.elevatorNo')" prop="ladderNo">
                <el-input v-model="elevator.ladderNo" :placeholder="$t('common.pleaseEnter')"></el-input>
              </el-form-item>
              <el-form-item :label="$t('elevator.locCode')" prop="locCode">
                <el-input v-model.trim="elevator.locCode" :placeholder="$t('common.pleaseEnter')"></el-input>
              </el-form-item>
            </div>
            <div class="vm-separate">
              <el-form-item :label="$t('useUnit.districtId')" prop="districtId">
                <vm-district-select v-model="elevator.districtId"></vm-district-select>
              </el-form-item>
              <el-form-item :label="$t('elevator.address')" prop="address">
                <el-input v-model.trim="elevator.address" :placeholder="$t('common.pleaseEnter')"></el-input>
              </el-form-item>
              <div></div>
            </div>
            <div v-if="$i18n.isCn" class="vm-separate">
              <el-form-item :label="$t('elevator.longitude')" prop="longitude">
                <el-input v-model="elevator.longitude" :placeholder="$t('common.pleaseSelect')" readonly></el-input>
              </el-form-item>
              <el-form-item :label="$t('elevator.latitude')" prop="latitude">
                <el-input v-model="elevator.latitude" :placeholder="$t('common.pleaseSelect')" readonly></el-input>
              </el-form-item>
              <div>
                <el-button
                  type="primary"
                  style="width: 90%; margin: 0 10%;"
                  @click="$refs.positionEdit.open(elevator.longitude, elevator.latitude)">
                  {{ $t("elevator.modifyLocation") }}
                </el-button>
                <elevator-position-edit ref="positionEdit" @select="onSelectElevatorPosition"></elevator-position-edit>
              </div>
            </div>
            <el-divider content-position="left"></el-divider>
            <div class="vm-separate">
              <el-form-item :label="$t('elevator.isHomeUse')" prop="isHomeUse">
                <el-select v-model="elevator.isHomeUse" style="width: 100%">
                  <el-option :label="$t('common.yes')" :value="true"></el-option>
                  <el-option :label="$t('common.no')" :value="false"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item
                v-show="elevator.isHomeUse"
                :label="$t('elevator.ownerPhoneNo')"
                prop="ownerPhoneNo">
                <el-input v-model="elevator.ownerPhoneNo" :placeholder="$t('common.pleaseEnter')"></el-input>
              </el-form-item>
              <el-form-item :label="$t('elevatorNetApply.proName')" prop="projectName">
                <el-input v-model.trim="elevator.projectName" :placeholder="$t('common.pleaseEnter')"></el-input>
              </el-form-item>
              <div v-show="!elevator.isHomeUse"></div>
            </div>

            <div v-if="userName === 'superAdmin' || userName === 'admin'" class="vm-separate">
              <el-form-item :label="$t('elevator.follow')" prop="isHomeUse">
                <el-select v-model="elevator.follow" style="width: 100%">
                  <el-option :label="$t('common.yes')" :value="true"></el-option>
                  <el-option :label="$t('common.no')" :value="false"></el-option>
                </el-select>
              </el-form-item>
              <div></div>
              <div></div>
            </div>
          </el-tab-pane>
          <el-tab-pane :label="$t('elevator.iot')" name="second">
            <div class="vm-separate">
              <el-form-item :label="$t('elevator.faultTemplate')">
                <el-input v-model="elevator.faultName" :placeholder="$t('common.pleaseSelect')" readonly>
                  <el-button slot="append" icon="el-icon-search" @click="$refs.selectFault.open()"></el-button>
                </el-input>
                <fault-select ref="selectFault" @select="onselectFault"></fault-select>
              </el-form-item>
              <el-form-item v-if="$i18n.isCn" :label="$t('elevator.accessPlatform')" prop="integrationKeys">
                <el-select
                  v-model="elevator.integrationKeys"
                  multiple
                  :placeholder="$t('elevator.accessPlatform')"
                  style="width: 100%"
                  clearable>
                  <el-option
                    v-for="item in integrationList"
                    :key="item.integrationKey"
                    :label="item.name"
                    :value="item.integrationKey"></el-option>
                </el-select>
              </el-form-item>
              <div v-if="!$i18n.isCn"></div>
              <div></div>
            </div>
            <el-divider content-position="left">{{ $t("elevator.deviceGateway") }}</el-divider>
            <div class="vm-separate">
              <el-form-item :label="$t('elevator.gateWayType')" prop="gateWayType">
                <el-select v-model="elevator.gateWayType" :placeholder="$t('common.pleaseSelect')" style="width: 100%">
                  <el-option :label="$t('elevator.gateWayType1')" :value="1"></el-option>
                  <!--                  <el-option :label="$l('elevator.gateWayType2')" :value="2"></el-option>-->
                  <el-option :label="$t('elevator.gateWayType3')" :value="3"></el-option>
                </el-select>
              </el-form-item>
              <div></div>
              <div></div>
            </div>
            <div v-if="elevator.gateWayType === 1">
              <step-gateway ref="stepGateway" :elevator="elevator"></step-gateway>
            </div>
            <div v-if="elevator.gateWayType === 3">
              <insh-gateway ref="inshGateway" :insh="elevator.deviceGatewayVo.deviceGatewayInshVo"></insh-gateway>
            </div>
            <el-divider content-position="left">
              {{ $t("elevator.videoEquip") }}
            </el-divider>
            <div class="vm-separate">
              <el-form-item :label="$t('tpLink.videoType')" prop="videoType">
                <el-select
                  v-model="elevator.videoType"
                  :placeholder="$t('common.pleaseSelect')"
                  style="width: 100%"
                  clearable>
                  <el-option
                    v-for="item in videoTypeList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item v-show="elevator.videoType === 2" :label="$t('route.tpLinkVideo')">
                <el-input v-model="elevator.deviceName" :placeholder="$t('common.pleaseSelect')" readonly>
                  <el-button slot="append" icon="el-icon-search" @click="$refs.tpVideoSelect.open()"></el-button>
                </el-input>
              </el-form-item>
              <tp-link-video-select ref="tpVideoSelect" @select="onSelectTPVideo"></tp-link-video-select>
              <el-form-item :label="$t('elevatorLcd.elevatorLcd')" prop="terminalID">
                <el-input v-model="elevator.terminalID" :placeholder="$t('common.pleaseSelect')" readonly>
                  <el-button slot="append" icon="el-icon-search" @click="$refs.elevatorLcdSelect.open(1)"></el-button>
                </el-input>
              </el-form-item>
              <elevator-lcd-select ref="elevatorLcdSelect" @select="onSelectElevatorLcd"></elevator-lcd-select>
              <div v-show="elevator.videoType !== 2"></div>
            </div>
            <div v-if="elevator.videoType === 1" class="vm-separate">
              <div style="padding-left: 50px;padding-top:8px;height: 30px">
                <el-radio-group v-model="elevator.videoState">
                  <el-radio :label="0">{{ $t("elevator.notInstalled") }}</el-radio>
                  <el-radio :label="1">{{ $t("elevator.installed_plug") }}</el-radio>
                  <el-radio :label="20">{{ $t("elevator.installed_link") }}</el-radio>
                </el-radio-group>
              </div>
            </div>
            <div v-if="elevator.videoType === 1" class="vm-separate">
              <el-form-item :label="$t('elevator.videoChannelId')">
                <el-input v-model="elevator.videoChannelId" :placeholder="$t('common.pleaseEnter')"></el-input>
              </el-form-item>
              <div></div>
              <div></div>
            </div>
            <el-form-item
              v-if="elevator.videoType === 1 && elevator.videoState === 20"
              :label="$t('elevator.videoUrl')">
              <el-input
                v-model.trim="elevator.videoUrl"
                type="textarea"
                :placeholder="$t('common.pleaseEnter')"></el-input>
            </el-form-item>
            <el-form-item
              v-if="elevator.videoType === 1 && elevator.videoState === 20"
              :label="$t('elevator.motorRoomVideoUrl')">
              <el-input
                v-model.trim="elevator.motorRoomVideoUrl"
                type="textarea"
                :placeholder="$t('common.pleaseEnter')"></el-input>
            </el-form-item>

            <el-divider></el-divider>
            <div class="vm-separate">
              <el-form-item :label="$t('elevator.floorDisplayTemplate')" prop="floorDisplayId">
                <el-input v-model="elevator.floorDisplayName" :placeholder="$t('common.pleaseSelect')" readonly>
                  <el-button slot="append" icon="el-icon-search" @click="$refs.selectFloorDisplay.open()"></el-button>
                </el-input>
                <floor-display-select ref="selectFloorDisplay" @select="onSelectFloorDisplay"></floor-display-select>
              </el-form-item>
              <div></div>
              <div>
                <el-button type="success" @click="batchDownLoad()">{{ $t("elevator.floorQr") }}</el-button>
                <div hidden>
                  <div v-for="(item, index) in elevator.elevatorFloors" :key="index">
                    <div :id="item.id">
                      <vue-qr
                        :text="getUrl(item.displayValue)"
                        logo-src="/static/images/step.png"
                        :logo-scale="0.3"
                        :logo-margin="5"
                        :correct-level="3"
                        :size="360"></vue-qr>
                    </div>
                  </div>
                </div>
                <div hidden>
                  <div>
                    <div :id="elevator.id">
                      <vue-qr
                        :text="getUrl(null)"
                        logo-src="/static/images/step.png"
                        :logo-scale="0.3"
                        :logo-margin="5"
                        :correct-level="3"
                        :size="360"></vue-qr>
                    </div>
                  </div>
                </div>
                <canvas id="box" style="display: none;background: #FFFFFF;" width="400px" height="500px"></canvas>
              </div>
            </div>
            <el-row>
              <el-table
                :data="elevator.elevatorFloors"
                max-height="300"
                border
                stripe
                style="width: 100%">
                <el-table-column prop="value" :label="$t('elevator.actualFloor')" width="150px">
                  <template slot-scope="scope">
                    {{ scope.row.value }}
                  </template>
                </el-table-column>
                <el-table-column prop="currentFloorCode" :label="$t('elevator.currentFloorCode')" width="200px">
                  <template slot-scope="scope">
                    <el-input v-model="scope.row.currentFloorCode"></el-input>
                  </template>
                </el-table-column>
                <el-table-column prop="faultFloorCode" :label="$t('elevator.faultFloorCode')" width="200px">
                  <template slot-scope="scope">
                    <el-input v-model="scope.row.faultFloorCode"></el-input>
                  </template>
                </el-table-column>
                <el-table-column prop="displayValue" :label="$t('elevator.displayedFloor')" width="150px">
                  <template slot-scope="scope">
                    <el-input v-model="scope.row.displayValue"></el-input>
                  </template>
                </el-table-column>
                <el-table-column prop="stopStatus" :label="$t('elevator.stopStatus')" width="200px">
                  <template slot-scope="scope">
                    <el-select v-model="scope.row.stopStatus" clearable :placeholder="$t('common.pleaseSelect')">
                      <el-option
                        v-for="item in stopStatusList"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"></el-option>
                    </el-select>
                  </template>
                </el-table-column>
                <el-table-column :label="$t('common.operation')" align="center">
                  <template slot-scope="scope">
                    <el-button type="text" @click.native.prevent="deleteRow(scope.$index)">
                      {{ $t("common.delete") }}
                    </el-button>
                  </template>
                </el-table-column>
              </el-table>
              <div style="margin-top: 10px;float: left">
                <el-button type="text" @click="handleAddFloorClick()"> + {{ $t("elevator.addFloorDisPlay") }}</el-button>
              </div>
            </el-row>
            <div class="vm-separate">
            </div>
            <div class="vm-separate">
            </div>
          </el-tab-pane>
          <el-tab-pane v-if="elevator.id !== 0" :label="$t('elevator.elevatorParts.elevatorParts')" name="third">
            <div style="display: flex;flex-direction: row">
              <div style="width: 20%;">
                <div>
                  <el-input v-model="filterText" style="width: 230px;" :placeholder="$t('common.pleaseEnter')" clearable></el-input>
                </div>
                <div v-loading="loadingTree" style=" height: 70vh;overflow-x:scroll;white-space: nowrap;">
                  <div style="width:100%">
                    <el-tree
                      ref="tree"
                      :data="data"
                      :props="defaultProps"
                      :filter-node-method="filterNode"
                      node-key="id"
                      default-expand-all
                      :expand-on-click-node="false">
                      <span slot-scope="{ node, data }" class="custom-tree-node">
                        <span @click="tree(data)">{{ node.label }}</span>
                      </span>
                    </el-tree>
                  </div>
                </div>
              </div>
              <div style="margin-left: 1%; width:79%">
                <el-button type="primary" @click="$refs.selectParts.open(0)">
                  {{ $t("elevator.elevatorParts.add") }}
                </el-button>
                <parts-select ref="selectParts" @select="onSelectParts"></parts-select>
                <vm-table
                  ref="vmTable1"
                  v-loading="loading1"
                  height="30vh"
                  url="elevatorParts"
                  :filter.sync="elevatorPartsSearch">
                  <el-table-column
                    prop="partsTypeName"
                    :label="$t('elevator.elevatorParts.category')"
                    width="150"></el-table-column>
                  <el-table-column
                    prop="partsNO"
                    :label="$t('elevator.elevatorParts.number')"
                    width="120"></el-table-column>
                  <el-table-column prop="partsName" :label="$t('elevator.elevatorParts.name')"></el-table-column>
                  <el-table-column prop="partsSn" :label="$t('elevator.elevatorParts.serialNumber')"></el-table-column>
                  <el-table-column
                    prop="floor"
                    :label="$t('elevator.elevatorParts.floor')"
                    width="70"></el-table-column>
                  <el-table-column prop="status" :label="$t('elevator.elevatorParts.status')" width="70">
                    <template slot-scope="scope">
                      <el-tag v-if="scope.row.status === 20" type="info">
                        {{ $t("elevator.elevatorParts.remove") }}
                      </el-tag>
                      <el-tag v-else-if="scope.row.status === 10" type="success">
                        {{ $t("elevator.elevatorParts.activated") }}
                      </el-tag>
                      <!--                      <el-tag v-else type="danger">-->
                      <!--                        {{$t("elevator.elevatorParts.notActivated")}}-->
                      <!--                      </el-tag>-->
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="activateTime"
                    :label="$t('elevator.elevatorParts.activateTime')"
                    width="130"></el-table-column>
                  <el-table-column
                    prop="activator"
                    :label="$t('elevator.elevatorParts.activator')"
                    width="70"></el-table-column>
                  <el-table-column
                    prop="createTime"
                    :label="$t('elevator.elevatorParts.createTime')"
                    width="130"></el-table-column>
                  <el-table-column :label="$t('common.operation')" width="100" align="center">
                    <template slot-scope="scope">
                      <el-button icon="el-icon-edit" circle @click="changeParts(scope.row)"></el-button>
                      <el-button
                        type="danger"
                        icon="el-icon-delete"
                        circle
                        @click="handleDelete(scope.row)"></el-button>
                    </template>
                  </el-table-column>
                </vm-table>
                <b>{{ $t("elevator.elevatorParts.replacementRecord") }}</b>
                <vm-table
                  ref="vmTable2"
                  v-loading="loading2"
                  height="30vh"
                  url="elevatorPartsReplacements"
                  :filter.sync="elevatorPartsReplacementsSearch">
                  <el-table-column
                    prop="partsTypeName"
                    :label="$t('elevator.elevatorParts.category')"></el-table-column>
                  <el-table-column prop="oldPartsName" :label="$t('elevator.elevatorParts.oldParts')"></el-table-column>
                  <el-table-column prop="newPartsName" :label="$t('elevator.elevatorParts.newParts')"></el-table-column>
                  <el-table-column
                    prop="replacementTime"
                    :label="$t('elevator.elevatorParts.replacementTime')"></el-table-column>
                  <el-table-column prop="activator" :label="$t('elevator.elevatorParts.activator')"></el-table-column>
                </vm-table>
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <template>
        <elevator-copy ref="submitCopy" @submitCopy="onSubmitCopy"></elevator-copy>
        <el-button type="success" @click="$refs.submitCopy.open(elevator.id)">{{ $t("elevator.copy") }}</el-button>
      </template>
      <el-button @click="dialogVisible = false">{{ $t("common.cancel") }}</el-button>
      <el-button type="primary" :loading="submitLoading" :disabled="saveDisabled" @click="handleSubmit">
        {{ $t("common.save") }}
      </el-button>
    </span>
  </el-dialog>
</template>
<script>
  import CompanySelect from "../company/CompanySelect.vue";
  import UseUnitSelect from "../company/useUnitCom/UseUnitSelect.vue";
  import QuantitySelect from "../quantity/QuantitySelect.vue";
  import FloorDisplaySelect from "../floorDisplay/FloorDisplaySelect.vue";
  import UserSelect from "../user/UserSelect.vue";
  import FaultSelect from "../fault/FaultSelect.vue";
  import ElevatorCopy from "./ElevatorCopy.vue";
  import ElevatorPositionEdit from "./ElevatorPositionEdit.vue";
  import PartsSelect from "../parts/PartsSelect";
  import VueQr from "vue-qr";
  import JSZip from "jszip";
  import FileSaver from "file-saver";
  import { stopStatus, stopStatus_en, typeToArray } from "@/util/constant";
  import VmDistrictSelect from "../../components/VmDistrictSelect";
  import CheckTemplateSelect from "@/views/dayWeekMonthManger/checkTemplates/checkTemplateSelect";
  import InshGateway from "@/views/elevator/gateway/InshGateway";
  import StepGateway from "@/views/elevator/gateway/StepGateway";
  import TpLinkVideoSelect from "@/views/vmVideo/TPLinkVideoSelect";
  import ElevatorLcdSelect from "@/views/smartScreen/ElevatorLcdSelect";
  import auth from "@/util/auth";

  const moduleName = "elevators";
  const moduleName_productType = "elevatorProductTypes";
  const moduleName_FloorMap = "floorDisplayMappings";

  export default {
    components: {
      TpLinkVideoSelect,
      ElevatorLcdSelect,
      StepGateway,
      InshGateway,
      VmDistrictSelect,
      ElevatorCopy,
      CompanySelect,
      UseUnitSelect,
      QuantitySelect,
      FloorDisplaySelect,
      UserSelect,
      PartsSelect,
      FaultSelect,
      ElevatorPositionEdit,
      VueQr,
      CheckTemplateSelect,
    },
    data() {
      let ruleNumber = [
        {
          type: "number",
          required: true,
          min: 1,
          message: this.$t("common.tip.notNull"),
          trigger: "change",
        },
      ];
      return {
        userName: auth.getUsername(),
        inspectionAuth: this.$auth(823),
        stopStatusList: typeToArray(this.$t("lang") === "en" ? stopStatus_en : stopStatus),
        dialogVisible: false,
        contentLoading: false,
        submitLoading: false,
        saveDisabled: false,
        activeTab: "first",
        productTypeList: [],
        placeList: [],
        map: null,
        searchValue: "",
        filterText: "",
        loadingTree: false,
        integrationList: [],
        data: [
          {
            id: 0,
            name: "全部",
            parentID: 0,
            children: [],
          },
        ],
        defaultProps: {
          children: "children",
          label: "name",
        },
        changePart: null,
        partsTypeId: 0,
        loading1: false,
        loading2: false,
        elevatorParts: {
          id: 0,
          elevatorID: 0,
          partsID: 0,
          partsName: "",
        },
        elevatorPartsSearch: {
          elevatorId: 0,
          partsTypeId: 0,
        },
        elevatorPartsReplacementsSearch: {
          elevatorId: 0,
          partsTypeId: 0,
        },
        dataUrls: [],
        elevator: {
          id: 0,
          no: "",
          name: "",
          productTypeId: "",
          createComId: 0,
          createComName: "",
          salesmanId: 0,
          salesman: "",
          salesmanTel: "",
          model: "",
          address: "",
          regNo: "",
          deviceId: "",
          factoryNo: "",
          controlCabinetLeaveNo: "",
          locCode: "",
          factoryDate: "",
          installDate: "",
          applyPlaceId: "",
          useUnitId: 0,
          useUnitName: "",
          agentId: 0,
          agentName: "",
          districtId: 0,
          maintEmpId: null,
          maintEmpName: "",
          replaceMaintEmpFlag: false,
          maintEmpId1: null,
          maintEmpName1: "",
          replaceMaintEmp1Flag: false,
          inNetState: true,
          isParallel: false,
          openStatus: 10,
          useState: 1,
          videoState: 0,
          videoChannelId: "",
          videoUrl: "",
          motorRoomVideoUrl: "",
          buildingNo: "",
          ladderNo: "",
          inNetDate: "",
          useDate: "",
          quantityOrganId: 0,
          quantityOrganName: "",
          installComId: 0,
          installComName: "",
          remakeComId: "",
          remakeComName: "",
          maintComId: 0,
          maintComName: "",
          elevatorPropertyComId: 0,
          elevatorPropertyComName: "",
          faultId: 0,
          faultName: "",
          dtuCode: "",
          nodeCode: "01",
          monitorType: 1,
          gateWayType: 1,
          iccid: "",
          integrationKey: "",
          integrationKeys: [],
          highestFloor: 0,
          ratedLoad: 0,
          ratedSpeed: "",
          monitorLimit: 5,
          rise: 0,
          angle: 0,
          width: 0,
          length: 0,
          warningParamId: 0,
          projectName: "",
          tiringTime: "30",
          unUseTime: "60",
          finishedTime: "1800",
          floorDisplayId: 0,
          floorDisplayName: "",
          elevatorFloors: [],
          terminalID: "",
          copyNum: 0,
          deviceGatewayVo: {
            deviceGatewayStepVo: {
              id: 0,
              signalType: "",
              deviceModel: "",
              simCode: "",
              simExpireDate: "",
              dtuCode: "",
              bindState: "",
              accessTime: "",
              softVer: "",
              hardVer: "",
              simICCID: "",
              signalStrength: "",
              temperature: "",
              electricityQuantity: "",
              ip: "",
              port: "",
              apnName: "",
              apnUserName: "",
              apnPassword: "",
              connectionStatus: "",
              inNetState: "",
              orgId: "",
              lastUpdateTime: "",
              onlineState: "",
              onlineUpdateTime: "",
              blackState: "",
              supportProtocol: "",
              developerId: "",
            },
            deviceGatewayInshVo: {
              id: 0,
              inshnId: "",
              inshnIo: 0,
              inshnSpeed: 2.0,
              inshnDoorType: 0,
              inshnDoorCnt: 8,
              inshnDoorTime: 10,
              inshnNextTime: 123,
              inshnRegtel: "",
              iccid: "",
              inNetTime: "",
              inshnFiltering: 3,
              inshnNetctype: "",
            },
          },
          longitude: "",
          latitude: "",
          isHomeUse: false,
          follow: false,
          ownerPhoneNo: "",
          tpVideoId: 0,
          videoType: "",
          deviceName: "",
          safetyOfficerId: 0,
          safetyOfficerName: "",
          safetyDirectorId: 0,
          safetyDirectorName: "",
          safetyMainerId: 0,
          safetyMainerName: "",
          brand: "",
          isAutoReport: false,
          inspectionTemplateName: "",
          inspectionTemplateId: "",
        },
        deviceInsh: {
          id: 0,
          inshnId: "",
          inshnIo: 0,
          inshnSpeed: 2.0,
          inshnDoorType: 0,
          inshnDoorCnt: 8,
          inshnDoorTime: 10,
          inshnNextTime: 123,
          inshnRegtel: "",
          iccid: "",
          inNetTime: "",
          inshnFiltering: 3,
          inshnNetctype: "",
        },
        deviceStep: {
          id: 0,
          signalType: "",
          deviceModel: "",
          simCode: "",
          simExpireDate: "",
          dtuCode: "",
          bindState: "",
          accessTime: "",
          softVer: "",
          hardVer: "",
          simICCID: "",
          signalStrength: "",
          temperature: "",
          electricityQuantity: "",
          ip: "",
          port: "",
          apnName: "",
          apnUserName: "",
          apnPassword: "",
          connectionStatus: "",
          inNetState: "",
          orgId: "",
          lastUpdateTime: "",
          onlineState: "",
          onlineUpdateTime: "",
          blackState: "",
          supportProtocol: "",
          developerId: "",
        },
        videoTypeList: [
          { value: 1, label: this.$t("tpLink.shiLian") },
          { value: 2, label: this.$t("tpLink.tpLink") },
        ],
        ruleValidate: {
          name: [
            { required: true, message: this.$t("elevator.name") + this.$t("common.tip.notNull"), trigger: "blur" },
          ],
          registerNo: [
            { required: true, message: this.$t("elevator.tip.needRegNO"), trigger: "blur" },
          ],
          leaveNo: [
            { required: true, message: this.$t("elevator.exFactoryNo") + this.$t("common.tip.notNull"), trigger: "blur" },
          ],
          useUnitId: ruleNumber,
          createComId: ruleNumber,
        },
      };
    },
    computed: {
      title() {
        return (this.elevator.id === 0 ? this.$t("common.add") : this.$t("common.edit")) + " " +
          this.$t("elevator.elevator");
      },
    },
    watch: {
      filterText(val) {
        this.$refs.tree.filter(val);
      },
    },
    methods: {
      open(id) {
        this.dialogVisible = true;
        this.showParts = false;
        this.elevator.id = id;
        this.elevatorParts.elevatorID = id;
        this.getAll();
        this.getIntegrationList();
        if (id > 0) {
          this.getData();
        } else {
          if (this.$i18n.isCn) {
            this.elevator.faultId = 453;
            this.elevator.faultName = "默认故障模板（内置）";
          } else {
            this.elevator.faultId = 454;
            this.elevator.faultName = "Default fault template（built-in）";
          }
        }
      },
      getData() {
        this.contentLoading = true;
        this.saveDisabled = true;
        this.$api.getById(moduleName, this.elevator.id).then(res => {
          this.contentLoading = false;
          this.saveDisabled = false;
          this.elevator = Object.assign(this.elevator, res.data);
          if (!this.elevator.deviceGatewayVo.deviceGatewayInshVo) {
            this.elevator.deviceGatewayVo.deviceGatewayInshVo = this.deviceInsh;
          }
          if (!this.elevator.deviceGatewayVo.deviceGatewayStepVo) {
            this.elevator.deviceGatewayVo.deviceGatewayStepVo = this.deviceStep;
          }
          if (this.elevator.faultId === 453 && !this.$i18n.isCn) {
            this.elevator.faultId = 454;
            this.elevator.faultName = "Default fault template（built-in）";
          }
        }).catch((error) => {
          this.contentLoading = false;
          if (error.response) {
            this.$message.error(this.$t("common.tip.getDataError") + "," + error.response.data.message);
          }
        });
      },
      saveElevator() {
        this.submitLoading = true;
        this.$api.save(moduleName, this.elevator).then(res => {
          this.submitLoading = false;
          this.dialogVisible = false;
          this.$emit("save-success", this.elevator.id === 0);
          this.$message.success(this.$t("common.tip.saveSuccess"));
        }).catch(error => {
          this.submitLoading = false;
          this.$message.error(this.$t("common.tip.saveError") + "," + error.response.data.message);
        });
      },
      handleSubmit() {
        this.$refs.formValidate.validate((valid, item) => {
          if (this.elevator.inNetState) {
            if (!this.elevator.inNetDate) {
              this.$message.error("已入网电梯入网日期不能为空");
              return;
            }
            if (!this.elevator.dtuCode) {
              this.$message.error("已入网电梯DTU编号不能为空");
              return;
            }
            for (let item of this.productTypeList) {
              if (item.value === this.elevator.productTypeId) {
                if (item.label) {
                  if (item.label.indexOf("扶梯") === -1 && item.label.indexOf("人行道") === -1) {
                    if (this.elevator.elevatorFloors.length === 0) {
                      this.$message.error("已入网电梯请完善楼层信息！");
                      return;
                    } else {
                      for (let item of this.elevator.elevatorFloors) {
                        if (!item.displayValue || !item.currentFloorCode || !item.faultFloorCode) {
                          if (!item.displayValue.trim() || !item.currentFloorCode.trim() || !item.faultFloorCode.trim()) {
                            this.$message.error("楼层信息不能为空！");
                            return;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }

          if (this.elevator.gateWayType === 1) {
            let step = this.$refs.stepGateway.getData();
            this.elevator.monitorType = step.monitorType;
            this.elevator.tpVideoId = step.tpVideoId;
            this.elevator.deviceName = step.deviceName;
            this.elevator.dtuCode = step.dtuCode;
            this.elevator.inNetDate = step.inNetDate;
            this.elevator.inNetState = step.inNetState;
            this.elevator.isParallel = step.isParallel;
            this.elevator.motorRoomVideoUrl = step.motorRoomVideoUrl;
            this.elevator.nodeCode = step.nodeCode;
            this.elevator.openStatus = step.openStatus;
            this.elevator.terminalID = step.terminalID;
            this.elevator.tiringTime = step.tiringTime;
            this.elevator.finishedTime = step.finishedTime;
            this.elevator.unUseTime = step.unUseTime;
            this.elevator.videoChannelId = step.videoChannelId;
            this.elevator.videoState = step.videoState;
            this.elevator.videoType = step.videoType;
            this.elevator.deviceGatewayVo.deviceGatewayStepVo.signalType = step.deviceGatewayVo.deviceGatewayStepVo.signalType;
            this.elevator.deviceGatewayVo.deviceGatewayStepVo.simExpireDate = step.deviceGatewayVo.deviceGatewayStepVo.simExpireDate;
            this.elevator.deviceGatewayVo.deviceGatewayStepVo.simCode = step.deviceGatewayVo.deviceGatewayStepVo.simCode;
            this.elevator.deviceGatewayVo.deviceGatewayStepVo.simICCID = step.deviceGatewayVo.deviceGatewayStepVo.simICCID;

            if (valid) {
              this.checkRule();
            } else {
              let itemName = "";
              if (item.leaveNo !== undefined) {
                itemName = this.$t("elevator.exFactoryNo");
              }
              if (item.useUnitId !== undefined) {
                itemName = this.$t("elevator.useUnit");
              }
              if (item.registerNo !== undefined) {
                itemName = this.$t("elevator.registerCode");
              }
              if (item.name !== undefined) {
                itemName = this.$t("elevator.name");
              }
              this.$message.error(this.$t("common.tip.submitError") + "!  " + itemName);
            }
          }

          if (this.elevator.gateWayType === 3) {
            this.elevator.deviceGatewayVo.deviceGatewayInshVo = this.$refs.inshGateway.getData();
            this.$refs.inshGateway.$refs.form.validate((valid1) => {
              if (valid && valid1) {
                this.checkRule();
              } else {
                let itemName = "";
                if (item.leaveNo !== undefined) {
                  itemName = this.$t("elevator.exFactoryNo");
                }
                if (item.useUnitId !== undefined) {
                  itemName = this.$t("elevator.useUnit");
                }
                if (item.registerNo !== undefined) {
                  itemName = this.$t("elevator.registerCode");
                }
                if (item.name !== undefined) {
                  itemName = this.$t("elevator.name");
                }
                this.$message.error(this.$t("common.tip.submitError") + "!  " + itemName);
              }
            });
          }

        });
      },
      checkRule() {
        if (!this.elevator.isHomeUse) {
          this.elevator.ownerPhoneNo = null;
        }
        this.saveElevator();
      },
      getUrl(floor) {
        let url = "";
        if (floor) {
          url = "https://" + window.location.host + "/?{\"ElevatorID\":" + this.elevator.id + ",\"CurrFloor\":\"" + floor + "\"}";
        }else {
          url = "https://" + window.location.host + "/?{\"ElevatorID\":" + this.elevator.id + ",\"CurrFloor\":\"" + "\"}";
        }
        console.log(url);
        return url;
      },
      //批量下载
      batchDownLoad() {
        this.dataUrls = [];
        for (const item of this.elevator.elevatorFloors) {
          this.paintCanvas(item.id, item.displayValue);
        }
        this.paintCanvas(this.elevator.id,null);
        this.handleBatchDownload();
      },
      //压缩图片
      handleBatchDownload() {
        const zip = new JSZip();
        this.dataUrls.forEach(item => {
          let arrData = item.picData.split(",");
          console.log(arrData);
          if (this.$i18n.isCn) {
            if (item.fileName) {
              zip.file(item.fileName, arrData[1], { base64: true });   //向zip中添加文件
            }else {
              zip.file("轿内", arrData[1], { base64: true });
            }

          } else {
            if (item.fileName) {
              let fileNameEn = item.fileName.replace("层", "Floor");
              zip.file(fileNameEn, arrData[1], {base64: true});
            }else {
              zip.file("轿内", arrData[1], {base64: true});
            }
          }
        });
        //打包压缩
        zip.generateAsync({ type: "blob" }).then(content => {
          if (this.$i18n.isCn) {
            FileSaver.saveAs(content, "【" + this.elevator.name + "】楼层呼梯二维码.zip");
          } else {
            FileSaver.saveAs(content, "【" + this.elevator.name + "】Qr Code of Floor Call Elevator.zip");
          }
        });
      },
      paintCanvas(id, floor) {
        console.log(id,floor);
        let c = document.getElementById("box");//获取canvas画布 画布大小和canvas大小一致
        let ctx = c.getContext("2d");
        ctx.clearRect(0, 0, c.width, c.height);// 清除画布
        // Canvas居中写字，参数（context对象，要写的字，字体，颜色，绘制的高度）
        function canvas_text(_paint, _text, _fontSzie, _color, _height) {
          _paint.font = _fontSzie;
          _paint.fillStyle = _color;
          _paint.textAlign = "center";
          _paint.textBaseline = "middle";
          _paint.fillText(_text, c.width / 2, _height);
        }

        canvas_text(ctx, " 微 信 扫 码 乘 梯 ", "38px bold 黑体", "#5856D6", 38);
        canvas_text(ctx, this.elevator.name, "20px bold 黑体", "black", 77);
        let div = document.getElementById(id);
        let img = div.getElementsByTagName("img")[0];
        ctx.drawImage(img, 20, 90, 360, 360);//绘制图片， 左，上，宽，高
        if(floor) {
          canvas_text(ctx, floor + " 层", "20px bold 黑体", "black", 470);
        }
        let dataUrl = c.toDataURL();
        // 批量下载
        if (floor) {
          this.dataUrls.push({ picData: dataUrl, fileName: floor + "层.jpg" });
        }else {
          this.dataUrls.push({ picData: dataUrl, fileName:  "轿内.jpg" });
        }

      },
      getAll() {
        this.$axios.all([this.getProductTypeList(), this.getPlaceList()]).then(this.$axios.spread(function(acct, perms) {
        // 两个请求现在都执行完成
        }));
      },
      getPlaceList() {
        this.$api.getList("dicts/ApplyPlace").then(response => {
          this.placeList = [];
          for (let place of response.data) {
            let item = { value: place.code, label: this.$i18n.isCn ? place.name : place.desc };
            this.placeList.push(item);
          }
        }).catch((error) => {
          if (error.response) {
            this.$message.error(this.$t("common.tip.getDataError") + "," + error.response.data.message);
          }
        });
      },
      getProductTypeList() {
        this.$api.getList(moduleName_productType).then(response => {
          this.productTypeList = [];
          for (let productType of response.data) {
            let item = "";
            if (this.$i18n.isCn) {
              item = { value: productType.elevatorProductTypeID, label: productType.elevatorProductTypeName };
            } else {
              item = { value: productType.elevatorProductTypeID, label: productType.elevatorProductTypeNameEn };
            }
            this.productTypeList.push(item);
          }
        }).catch((error) => {
          if (error.response) {
            this.$message.error(this.$t("common.tip.getDataError") + "," + error.response.data.message);
          }
        });
      },
      onSubmitCopy(copyNum) {
        if (copyNum > 0) {
          this.elevator.id = 0;
          this.elevator.copyNum = copyNum;
          this.elevator.deviceGatewayVo.deviceGatewayStepVo.deviceModel = "";
          this.elevator.deviceGatewayVo.deviceGatewayStepVo.simCode = "";
          this.elevator.iccid = "";
          this.elevator.videoChannelId = "";
          this.elevator.longId = 0;
          this.elevator.dtuCode = "";
          this.elevator.inNetState = false;
          this.elevator.isHomeUse = false;
          this.elevator.follow = false;
          this.handleSubmit();
        } else {
          this.$message.error(this.$t("common.tip.needNum"));
        }
      },
      propertyComClear() {
        this.elevator.elevatorPropertyComId = 0;
        this.elevator.elevatorPropertyComName = "";
      },
      maintComClear() {
        this.elevator.maintComId = 0;
        this.elevator.maintComName = "";
      },
      installComClear() {
        this.elevator.installComId = 0;
        this.elevator.installComName = "";
      },
      remakeComClear() {
        this.elevator.remakeComId = 0;
        this.elevator.remakeComName = "";
      },
      maintEmpClear() {
        this.elevator.maintEmpId = 0;
        this.elevator.maintEmpName = "";
      },
      maintEmp1Clear() {
        this.elevator.maintEmpId1 = 0;
        this.elevator.maintEmpName1 = "";
      },
      selectMaintEmp() {
        if (this.elevator.maintComId === 0) {
          this.$message.error(this.$t("elevator.maintComSelectTip"));
          return;
        }
        this.$refs.onSelectMaintEmp.open();
      },
      selectMaintEmp1() {
        if (this.elevator.maintComId === 0) {
          this.$message.error(this.$t("elevator.maintComSelectTip"));
          return;
        }
        this.$refs.onSelectMaintEmp1.open();
      },
      selectPropertyComEmp() {
        if (this.elevator.elevatorPropertyComId === 0) {
          this.$message.error(this.$t("elevator.propertyComSelectTip"));
          return;
        }
        this.$refs.onSelectPropertyEmp.open();
      },
      selectPropertyComEmp1() {
        if (this.elevator.elevatorPropertyComId === 0) {
          this.$message.error(this.$t("elevator.propertyComSelectTip"));
          return;
        }
        this.$refs.onSelectPropertyEmp1.open();
      },
      selectPropertyComEmp2() {
        if (this.elevator.elevatorPropertyComId === 0) {
          this.$message.error(this.$t("elevator.propertyComSelectTip"));
          return;
        }
        this.$refs.onSelectPropertyEmp2.open();
      },
      onSelectPropertyEmp(row) {
        // if (this.elevator.safetyOfficerId === row.id) {
        //   this.$message.error(this.$l("elevator.samePropertySafetyOfficer", "不能选择同一个安全员!"));
        //   return;
        // }
        this.elevator.safetyOfficerId = row.id;
        this.elevator.safetyOfficerName = row.name;
      },
      onSelectPropertyEmp1(row) {
        // if (this.elevator.safetyDirectorId === row.id) {
        //   this.$message.error(this.$l("elevator.samePropertySafetyDirector", "不能选择同一个安全总监!"));
        //   return;
        // }
        this.elevator.safetyDirectorId = row.id;
        this.elevator.safetyDirectorName = row.name;
      },
      onSelectSalesman(row) {
        this.elevator.salesmanId = row.id;
        this.elevator.salesman = row.name;
        this.elevator.salesmanTel = row.phone;
      },
      onSelectPropertyEmp2(row) {
        // if (this.elevator.safetyDirectorId === row.id) {
        //   this.$message.error(this.$l("elevator.samePropertySafetyDirector", "不能选择同一个安全总监!"));
        //   return;
        // }
        this.elevator.safetyMainerId = row.id;
        this.elevator.safetyMainerName = row.name;
      },
      propertyEmpClear() {
        this.elevator.safetyOfficerId = 0;
        this.elevator.safetyOfficerName = "";
      },
      salesmanClear() {
        this.elevator.salesmanId = 0;
        this.elevator.salesman = "";
        this.elevator.salesmanTel = "";
      },
      propertyEmp1Clear() {
        this.elevator.safetyDirectorId = 0;
        this.elevator.safetyDirectorName = "";
      },
      propertyEmp2Clear() {
        this.elevator.safetyMainerId = 0;
        this.elevator.safetyMainerName = "";
      },
      getTreeData() {
        this.loadingTree = true;
        this.$axios.get("partsTypes/tree").then((res) => {
          this.data[0].children = res.data;
          this.loadingTree = false;
        });
      },
      getList1(pageIndex) {
        this.elevatorPartsSearch.elevatorId = this.elevator.id;
        this.elevatorPartsSearch.partsTypeId = this.partsTypeId;
        this.$refs.vmTable1.getList(pageIndex);
      },
      getList2(pageIndex) {
        this.elevatorPartsReplacementsSearch.elevatorId = this.elevator.id;
        this.elevatorPartsReplacementsSearch.partsTypeId = this.partsTypeId;
        this.$refs.vmTable2.getList(pageIndex);
      },
      tree(data) {
        this.partsTypeId = data.id;
        this.refreshList(1);
      },
      tabClick(tab, event) {
        if (tab.name === "third") {
          this.partsTypeId = 0;
          this.defaultProps = "";
          this.getList1(1);
          this.getList2(1);
          this.getTreeData();
        }
      },
      deleteRow(index) {
        this.$confirm(this.$t("common.confirmTip") + "?", this.$t("common.tips"), {
          confirmButtonText: this.$t("common.confirm"),
          cancelButtonText: this.$t("common.cancel"),
          type: "warning",
        }).then(() => {
          let rows = this.elevator.elevatorFloors;
          rows.splice(index, 1);
          for (let i = index; i < this.elevator.elevatorFloors.length; i++) {
            this.elevator.elevatorFloors[i].value = i + 1;
          }
        });
      },
      handleAddFloorClick() {
        let elevatorFloor = {
          value: this.elevator.elevatorFloors.length + 1,
          displayValue: "",
          currentFloorCode: "",
          faultFloorCode: "",
          stopStatus: 1,
        };
        this.elevator.elevatorFloors.push(elevatorFloor);
      },
      changeParts(row) {
        this.changePart = row;
        this.$refs.selectParts.open(1);
      },
      handleDelete(row) {
        this.$confirm(this.$t("common.delete") + this.$t("common.isDelete") + "?", this.$t("common.tips"), {
          confirmButtonText: this.$t("common.confirm"),
          cancelButtonText: this.$t("common.cancel"),
          type: "warning",
        }).then(() => {
          this.$api.deleteById("elevatorParts", row.id).then(res => {
            this.getList1(this.currentPage1);
            this.$message.success(this.$t("common.tip.deleteSuccess"));
          }).catch((error) => {
            if (error.response) {
              this.$message.error(this.$t("common.tip.deleteError") + "," + error.response.data.message);
            }
          });
        });
      },
      getDefaultFault(companyName) {
        console.log(companyName);
        let params = {
          "companyName": companyName,
        };
        this.$api.getData("faults/defaultFault", params).then(res => {
          if (res.data) {
            this.elevator.faultId = res.data.id;
            this.elevator.faultName = res.data.name;
          }
        }).catch((err) => {
          this.$message.error(err);
        });
      },
      filterNode(value, data, node) {
        if (!value) {
          return true;
        }
        if (node.parent.parent !== null && node.parent.visible) {
          return true;
        }
        return node.label.indexOf(value) !== -1;
      },
      onSelectTPVideo(row) {
        this.elevator.tpVideoId = row.devId;
        this.elevator.deviceName = row.deviceName;
      },
      onSelectElevatorLcd(row) {
        this.elevator.terminalID = row.terminalID;
      },
      onSelectUseUnit(row) {
        this.elevator.useUnitId = row.id;
        this.elevator.useUnitName = row.name;
      },
      onSelectAgentCom(row) {
        this.elevator.agentId = row.id;
        this.elevator.agentName = row.name;
      },
      onSelectCreateCom(row) {
        this.elevator.createComId = row.id;
        this.elevator.createComName = row.name;
        // 选择制造企业之后查找默认 故障模板
        this.getDefaultFault(this.elevator.createComName);
      },
      onSelectMaintCom(row) {
        this.elevator.maintComId = row.id;
        this.elevator.maintComName = row.name;
        this.elevator.maintEmpId = null;
        this.elevator.maintEmpName = "";
        this.elevator.maintEmpId1 = null;
        this.elevator.maintEmpName1 = "";
      },
      onSelectPropertyCom(row) {
        this.elevator.elevatorPropertyComId = row.id;
        this.elevator.elevatorPropertyComName = row.name;
      },
      onSelectInstallCom(row) {
        this.elevator.installComId = row.id;
        this.elevator.installComName = row.name;
      },
      onSelectRemakeCom(row) {
        this.elevator.remakeComId = row.id;
        this.elevator.remakeComName = row.name;
      },
      onSelectQuantityOrgan(row) {
        this.elevator.quantityOrganId = row.id;
        this.elevator.quantityOrganName = row.name;
      },
      onSelectMaintEmp(row) {
        if (this.elevator.maintEmpId1 === row.id) {
          this.$message.error("不能选择同一个维保工!");
          return;
        }
        if (this.elevator.maintEmpId > 0 && row.id !== this.elevator.maintEmpId) {
          let params = {
            "maintEmpId": this.elevator.maintEmpId,
            "elevatorId": this.elevator.id,
          };
          this.$api.getData("elevators/maintEmpJob", params).then(res => {
            if (res.data.result) {
              this.$confirm("是否把原电梯维保工【" + this.elevator.maintEmpName + "】维保的电梯全部替换为当前维保工【" + row.name + "】？", this.$t("common.tips"), {
                confirmButtonText: this.$t("common.yes"),
                cancelButtonText: this.$t("common.no"),
                type: "warning",
              }).then((res) => {
                this.elevator.maintEmpId = row.id;
                this.elevator.maintEmpName = row.name;
                this.elevator.replaceMaintEmpFlag = true;
              }).catch(() => {
                this.elevator.maintEmpId = row.id;
                this.elevator.maintEmpName = row.name;
                this.elevator.replaceMaintEmpFlag = false;
              });
            } else {
              this.elevator.maintEmpId = row.id;
              this.elevator.maintEmpName = row.name;
            }
          });
        } else {
          this.elevator.maintEmpId = row.id;
          this.elevator.maintEmpName = row.name;
        }
      },
      onSelectMaintEmp1(row) {
        if (this.elevator.maintEmpId === row.id) {
          this.$message.error("不能选择同一个维保工!");
          return;
        }
        if (this.elevator.maintEmpId1 > 0 && row.id !== this.elevator.maintEmpId1) {
          let params = {
            "maintEmpId": this.elevator.maintEmpId1,
            "elevatorId": this.elevator.id,
          };
          this.$api.getData("elevators/maintEmpJob", params).then(res => {
            if (res.data.result) {
              this.$confirm("是否把原电梯维保工【" + this.elevator.maintEmpName1 + "】维保的电梯全部替换为当前维保工【" + row.name + "】？", this.$t("common.tips"), {
                confirmButtonText: this.$t("common.yes"),
                cancelButtonText: this.$t("common.no"),
                type: "warning",
              }).then((res) => {
                this.elevator.maintEmpId1 = row.id;
                this.elevator.maintEmpName1 = row.name;
                this.elevator.replaceMaintEmp1Flag = true;
              }).catch(() => {
                this.elevator.maintEmpId1 = row.id;
                this.elevator.maintEmpName1 = row.name;
                this.elevator.replaceMaintEmp1Flag = false;
              });
            } else {
              this.elevator.maintEmpId1 = row.id;
              this.elevator.maintEmpName1 = row.name;
            }
          });
        } else {
          this.elevator.maintEmpId1 = row.id;
          this.elevator.maintEmpName1 = row.name;
        }
      },
      onselectFault(row) {
        this.elevator.faultId = row.id;
        this.elevator.faultName = row.desc;
      },
      onSelectFloorDisplay(row) {
        this.elevator.floorDisplayId = row.id;
        this.elevator.floorDisplayName = row.name;
        this.getFloorDisplayMapping();
      },
      getFloorDisplayMapping() {
        this.$api.getById(moduleName_FloorMap, this.elevator.floorDisplayId).then(res => {
          this.elevator.elevatorFloors = res.data;
        }).catch((error) => {
          if (error.response) {
            this.$message.error(this.$t("common.tip.getDataError") + "," + error.response.data.message);
          }
        });
      },
      onSelectElevatorPosition(obj) {
        this.elevator.longitude = obj.longitude;
        this.elevator.latitude = obj.latitude;
      },
      onSelectParts(row, val) {
        for (let item of this.$refs.vmTable1.tableData) {
          if (item.partsID === row.id) {
            this.$message.error("配件列表已存在此配件");
            return;
          }
          if (val === 0) {
            if (item.partsTypeName === "主板" && row.partsTypeName === "主板") {
              this.$message.error("主板已经存在");
              return;
            }
          }
        }
        if (this.changePart) {
          this.$confirm("将 " + this.changePart.partsName + " 替换成 " + row.name + " ？", this.$t("common.tips"), {
            confirmButtonText: this.$t("common.confirm"),
            cancelButtonText: this.$t("common.cancel"),
            type: "warning",
          }).then(() => {
            this.elevatorParts.id = this.changePart.id;
            this.elevatorParts.partsID = row.id;
            this.elevatorParts.partsName = row.name;
            this.saveElevatorParts();
          });
        } else {
          this.elevatorParts.partsID = row.id;
          this.elevatorParts.partsName = row.name;
          this.saveElevatorParts();
        }
      },
      saveElevatorParts() {
        this.$api.save("elevatorParts", this.elevatorParts).then(res => {
          this.refreshList(this.currentPage1);
          this.$message.success(this.$t("common.tip.saveSuccess"));
        }).catch(error => {
          this.$message.error(this.$t("common.tip.saveError") + "," + error.response.data.message);
        });
      },
      refreshList(pageIndex) {
        // 两个请求现在都执行完成
        this.$axios.all([this.getList1(pageIndex), this.getList2(1)]).then(this.$axios.spread(function(acct, perms) {
        }));
      },
      onDialogClose() {
        this.saveDisabled = false;
        this.map = null;
        this.contentLoading = false;
        // this.$refs.formValidate.resetFields();
        this.$copy(this.$data, this.$options.data.call(this), "ruleValidate");
      },
      getIntegrationList() {
        this.$http.get("integrations/list").then(({ data }) => {
          this.integrationList = data;
        });
      },

      // 选择排查模板
      checkTemplateSelect(row) {
        this.elevator.inspectionTemplateName = row.templateName;
        this.elevator.inspectionTemplateId = row.id;
      },

      clearInspectionTemplateInfo() {
        this.elevator.inspectionTemplateName = "";
        this.elevator.inspectionTemplateId = 0;
      },
    },
  };
</script>

<style lang="scss" scoped></style>
